import styled from 'styled-components'
import { Input as _Input } from 'antd'
export const SearchInput = styled(_Input)<{ backgroundcolorsecondary?: string }>`
  fontsize: 17px;
  fontweight: 400;
  height: 40px;
  border: none;
  border-radius: 8px !important;
  .ant-input {
    background-color: ${(props) => props.backgroundcolorsecondary};
    font-size: 17px !important;
    font-weight: 400 !important;
  }
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper-focus {
    box-shadow: none !important;
  }
`