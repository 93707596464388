import { Button, Layout, Modal, Space as _Space, Typography } from 'antd'
import styled from 'styled-components'
import { ProfileAction } from '../../components/HeaderActions/Profile'
import { ReloadAction } from '../../components/HeaderActions/Reload'
import { AdPlatformAction } from '../../components/HeaderActions/AdPlatform'
import { AttributionCard } from '../../components/AttributionCard'
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout'
import connectAccount from '../../assets/dashboard_assets/connect-accont.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Routes } from '../../Routes'
import { useContext, useEffect, useState } from 'react'
import { useGenerateFacebookLinkMutation } from '../../__generated__/graphql'
import { JwtContext } from '../../contexts/jwtContext'
import { DashboardRangePicker } from '../../components/DashboardRangePicker'
import { MetricsContainer } from '../../components/MetricsContainer'
import UTMWarningModal from '../QuickStart/Subscription/UTMWarningModal'
import { AttributionSelect } from '../../components/HeaderActions/AttributionSelect'
const { Content: _Content } = Layout
const { Text } = Typography
const Content = styled(_Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
`

const ContentWrapper = styled.div`
  min-height: 360px;
  width: 100%;
  max-width: 1265px;
`

const Space = styled(_Space)`
  .ant-space-item {
    width: 100%;
  }
`

export const Dashboard = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalOpen, setIsModalVisible] = useState(searchParams.get('success') === 'true')
  const handleOk = () => {
    setIsModalVisible(false)
    navigate('.', { replace: true })
  }
  const decodedToken = useContext(JwtContext)
  const [generateFacebookLink, { data: adPlatformData, loading: adPlatformLoading }] = useGenerateFacebookLinkMutation()

  useEffect(() => {
    generateFacebookLink({
      variables: {
        id: decodedToken?.stores[0] ?? '',
      },
    })
  }, [])

  return (
    <ResponsiveSiderLayout
      title="Dashboard"
      primaryPageKey="dashboard"
      actions={[<ReloadAction key={1} />, <AttributionSelect key={2} />, <AdPlatformAction key={3} />, <DashboardRangePicker key={4} />, <ProfileAction key={5} />]}
      mobileActions={[<ProfileAction key={4} />]}
    >
      <Content>
        <ContentWrapper>
          <MetricsContainer />
          <AttributionCard />
        </ContentWrapper>
      </Content>
      <UTMWarningModal isOpen={isModalOpen} onClose={handleOk} />
      {!adPlatformLoading && (
        <Modal centered open={!adPlatformData?.generateFacebookLinkByStoreId.connected} footer={null} closable={false}>
          <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }} align="center">
            <img src={connectAccount}></img>
            <div style={{ marginTop: -40 }}>
              <Text style={{ fontWeight: 700, fontSize: 32 }}>Connect your account to view data</Text>
            </div>
            <Button onClick={() => navigate(Routes.QUICK_START)} type="primary" block style={{ fontWeight: 700, fontSize: 16, padding: '12px 24px', height: 60, width: '100%', borderRadius: 14 }}>
              Connect Account Now
            </Button>
          </Space>
        </Modal>
      )}
    </ResponsiveSiderLayout>
  )
}
