import { useContext } from 'react'
import { Form, Typography, message } from 'antd'
import { Button } from '../StyledComponents/Button'
import { Input } from '../StyledComponents/Input'
import { FormItem } from '../StyledComponents/FormItem'
import { Card } from '../StyledComponents/Card'
import { useCreateStoreMutation } from '../../__generated__/graphql'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../Routes'
import { Spin } from '../StyledComponents'
import { JwtContext } from '../../contexts/jwtContext'
import { default as ShopifySvg } from '../../assets/shopify.svg'
import styled from 'styled-components'
const { Title } = Typography

const Shopify = styled.img`
  height: 25px;
  width: 25px;
  margin: 0 10px 0 5px;
`;

interface CreateStoreFormValues {
  name: string
  sourceStoreId: string
}

export const CreateStoreForm = () => {
  const navigate = useNavigate()
  const decodedToken = useContext(JwtContext)

  const organizationId = decodedToken?.user?.organizationId

  const [createStoreMutation, { loading }] = useCreateStoreMutation({
    onCompleted: (data) => {
      switch (data?.createStore?.__typename) {
        case 'Store':
          navigate(Routes.DASHBOARD)
          break
        case 'UnableToCreateStore':
          message.error(data.createStore?.error, 5)
          break
        default:
          message.error('Create Store failed.', 5)
      }
    },
    onError: (error) => {
      console.log('error', error)
      message.error('An unknown erorr occured')
    },
  })

  const handleSubmit = ({ name }: CreateStoreFormValues) => {
    createStoreMutation({
      variables: {
        input: {
          name,
          organizationId: organizationId ?? '',
        },
      },
    })
  }

  return (
    <>
      <Card style={{ textAlign: 'center' }}>
        <Title style={{ textAlign: 'center', fontSize: '32px', fontWeight: 700 }}>Setup Your Shopify Store</Title>
        <Form name="create-org-form" layout="vertical" requiredMark={false} onFinish={handleSubmit}>
          <FormItem label="Enter your shopify store name" name="name" rules={[{ required: true, message: 'Please enter the name of your shopify store.' }]}>
            <Input prefix={<Shopify src={ShopifySvg} />} placeholder="Shopify Store name" />
          </FormItem>
          <FormItem>
            <Button loading={loading} block type="primary" htmlType="submit">
              {loading ? <Spin /> : 'Finish'}
            </Button>
          </FormItem>
        </Form>
      </Card>
    </>
  )
}
