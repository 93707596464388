import { ErrorBoundary } from '../pages/ErrorBoundary'
import { Login } from '../pages/Login'
import { Register } from '../pages/Register'
import { Routes } from '../Routes'
import { Navigate, RouteObject } from 'react-router-dom'
import { useContext } from 'react'
import { JwtContext } from '../contexts/jwtContext'
import { LocalStorageKeys, LocalStorageTypes } from '../common/types/localStorage'
import { useLocalStorage } from '../hooks/useLocalStorage'

const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const token = useLocalStorage(LocalStorageKeys.AuthToken, LocalStorageTypes.String)
  const decodedToken = useContext(JwtContext)
  return <>{token && decodedToken?.scope === 'full' ? <Navigate to="/" /> : children}</>
}

export const publicRoutes: RouteObject[] = [
  {
    path: Routes.REGISTER,
    element: (
      <PublicRoute>
        <Register />
      </PublicRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.LOGIN,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
    errorElement: <ErrorBoundary />,
  },
]