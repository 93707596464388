import { Row as _Row } from 'antd'
import { VerifyCodeForm } from '../../components/VerifyCodeForm'
import { PasswordResetLayout } from '../../layouts/PasswordResetLayout'

export const VerifyCode = () => {
  return (
    <PasswordResetLayout>
      <VerifyCodeForm />
    </PasswordResetLayout>
  )
}
