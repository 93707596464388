export const breakpoints = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}

export const antdScreenSizes: { [key: string]: number } = {
  screenLG: 992,
  screenLGMax: 1199,
  screenLGMin: 992,
  screenMD: 768,
  screenMDMax: 991,
  screenMDMin: 768,
  screenSM: 576,
  screenSMMax: 767,
  screenSMMin: 576,
  screenXL: 1200,
  screenXLMax: 1599,
  screenXLMin: 1200,
  screenXS: 480,
  screenXSMax: 575,
  screenXSMin: 480,
  screenXXL: 1600,
  screenXXLMin: 1600,
}
