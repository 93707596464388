import { useSelector } from '@xstate/react'
import { useContext } from 'react'
import { SortInput } from '../../__generated__/graphql'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { AttributionTableTabs, DashboardMachineState, RowKey } from '../../machines/dashboardMachine/dashboardMachine'
import { getColumns } from './columns'
import { AttributionTable } from '../AttributionTable'

const selectedAdsetsSelector = (state: DashboardMachineState) => state.context.selectedAdsets
const limitSelector = (state: DashboardMachineState) => state.context.limit
const offsetSelector = (state: DashboardMachineState) => state.context.offset.adsets
const sortSelector = (state: DashboardMachineState) => state.context.sort.adsets
const dataSelector = (state: DashboardMachineState) => state.context.data.adsetsQuery

export const AdsetsTable = () => {
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService

  const selectedAdsets = useSelector(globalServices.dashboardService, selectedAdsetsSelector)
  const limit = useSelector(globalServices.dashboardService, limitSelector)
  const offset = useSelector(globalServices.dashboardService, offsetSelector)
  const sort = useSelector(globalServices.dashboardService, sortSelector)
  const data = useSelector(globalServices.dashboardService, dataSelector)

  const setSelectedAdsets = (selectedAdsets: RowKey[]) => send({ type: 'SET_SELECTED_ADSETS_EVENT', selectedAdsets })
  const setOffset = (offset: number) => send({ type: 'SET_ADSETS_TABLE_OFFSET_EVENT', offset })
  const setLimit = (limit: number) => send({ type: 'SET_LIMIT_EVENT', limit })
  const setAdsetsSort = (sort: SortInput[]) => send({ type: 'SET_ADSETS_TABLE_SORT_EVENT', sort })
  const handleAdsetClick = (key: RowKey) => send({ type: 'ADSET_CLICK_EVENT', selectedAdsets: [key], tab: AttributionTableTabs.ADS })

  return (
    <AttributionTable
      title="Adsets"
      sort={sort}
      columns={getColumns(true, handleAdsetClick)}
      // loading={loading}
      // TODO: Get Rid of mapping here by backend returning correct values and keys
      data={(data?.adsets || []).map(({ id, name, status, dailyBudget, spend, orders, ordersValue, sourceOrders, sourceOrdersValue, sourceAdsetId }) => {
        return {
          key: id,
          name,
          status,
          spend: spend ?? 0,
          budget: dailyBudget ?? 0,
          on: true,
          orders: orders,
          ordersValue: ordersValue,
          sourceOrders: sourceOrders,
          sourceOrdersValue: sourceOrdersValue,
          id: sourceAdsetId,
        }
      })}
      limit={limit}
      total={data?.total}
      selectedRowKeys={selectedAdsets}
      setLimit={setLimit}
      offset={offset}
      setSort={setAdsetsSort}
      setOffset={setOffset}
      setSelectedRowKeys={setSelectedAdsets}
    />
  )
}
