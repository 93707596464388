import { AppSider } from '../../components/AppSider';
import { AppHeader } from '../../components/AppHeader/AppHeader';
import { ProfileAction } from '../../components/HeaderActions/Profile/Profile';
import Card from 'antd/es/card/Card';
import { Layout } from 'antd';
import { SupportSettingsForm } from '../../components/SupportSettingsForm';
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout';
import styled from 'styled-components';

const { Content: _Content } = Layout
const Content = styled(_Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
`

export const SupportSettings = () => {
    return (
        <ResponsiveSiderLayout
            title="Need Help?"
            primaryPageKey="support"
            actions={[<ProfileAction key={1} />]}
            mobileActions={[<ProfileAction key={1} />]}
        >
            <Content style={{ margin: '24px', padding: '24px', background: 'white' }}>
                <SupportSettingsForm />
            </Content>
        </ResponsiveSiderLayout>
    );
};
