import jwtDecode, { JwtPayload } from 'jwt-decode'
import { LocalStorageKeys } from '../common/types/localStorage'
import { localStorageRemoveItem } from './storage'

const privateKeys = [
  LocalStorageKeys.AuthToken,
  LocalStorageKeys.PersistedState,
  LocalStorageKeys.Timezone,
  LocalStorageKeys.SiderCollapse,
  LocalStorageKeys.CodeEmail,
  LocalStorageKeys.CodePhone,
  LocalStorageKeys.CodeChannel,
  LocalStorageKeys.CodeSentAt,
  LocalStorageKeys.CodeExpiresAt,
  LocalStorageKeys.IsMobile,
]

// const publicKeys = [LocalStorageKeys.Email, LocalStorageKeys.RememberMe]

export const clearPrivateStorageLogout = () => {
  privateKeys.forEach((key) => localStorageRemoveItem(key))
}

export const clearPrivateStorageLogin = () => {
  const keysToRemove = privateKeys.filter((key) => key !== LocalStorageKeys.AuthToken)
  keysToRemove.forEach((key) => localStorageRemoveItem(key))
}

export interface Jwt extends JwtPayload {
  user: {
    id: string
    firstName: string
    lastName: string
    email: string
    role: string
    phone: string
    organizationId: string
    subscribed: boolean
  }
  stores: string[]
  scope: string
}

export function decodeToken(token: string | null) {
  try {
    if (!token) return null
    return jwtDecode<Jwt>(token)
  } catch (error) {
    localStorageRemoveItem(LocalStorageKeys.AuthToken)
    return null
  }
}
