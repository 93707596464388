import { Country } from 'country-list'

export const sortCountries = (a: Country, b: Country) => {
  if (a.code === 'US') {
    return -1
  } else if (b.code === 'US') {
    return 1
  } else {
    return a.name.localeCompare(b.name)
  }
}
