import { Divider, Space, Typography, theme } from 'antd'
import ReactPlayer from 'react-player'
import Step1 from '../../../assets/quickstart_assets/step1.svg'
import Step2 from '../../../assets/quickstart_assets/step2.svg'
import Step3 from '../../../assets/quickstart_assets/step3.svg'
import styled from 'styled-components'
import { CopyableText } from '../../../components/StyledComponents'
import { useContext } from 'react'
import { JwtContext } from '../../../contexts/jwtContext'
import { SetupStep } from '../../../components/SetupStep'
const { Text } = Typography

const StepHeading = styled(Text)`
  font-weight: 600;
  font-size: 20px;
`
const SetupSteps = [
  {
    heading: 'Step 1',
    img: Step1,
    sub: 'Paste the script in your theme.liquid file. Place it right under the <head> as shown below. You can get there by going to: online store → Edit Code → theme.liquid file',
  },
  { heading: 'Step 2', img: Step2, sub: 'Paste the script into your Order Status Page under additional scripts. You can get there by going to: settings → checkouts → Order status page' },
  {
    heading: 'Step 3',
    img: Step3,
    sub: "This is ONLY needed if you're using a post-purchase upsell app like Zipify OCU. If you are using a post purchase app then you need to also paste the script into your Post-purchase page. You get there by going to: settings → checkouts → Post-purchase page",
  },
]

export const SetupOurPixel = () => {
  const decodedToken = useContext(JwtContext)

  const storedId = decodedToken?.stores[0]
  const pixelScript = `<script src="${import.meta.env.VITE_BASE_SERVER_URL}/api/v1/pixel?storeId=${storedId}"></script>`
  const {
    token: { colorBgLayout },
  } = theme.useToken()
  return (
    <>
      <Space style={{ width: '100%' }} size={'large'} direction="vertical">
      <ReactPlayer
            width="100%"
            height="auto"
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            pip={true}
            controls={true}
            url="https://vimeo.com/914962176"
            style={{ aspectRatio: '16 / 9' }}
          />
        <Divider />
        <Space style={{ width: '100%' }} direction="vertical">
          <StepHeading>Paste this code into the steps below</StepHeading>
          <CopyableText text={pixelScript} backgroundColor={colorBgLayout} />
        </Space>
        {SetupSteps.map(({ img, heading, sub }, index) => {
          return <SetupStep key={index} media={{ type: 'image', src: img }} heading={heading} sub={sub} />
        })}
      </Space>
    </>
  )
}

export default SetupOurPixel
