import { Space } from 'antd'
import { AdPlatformCard } from './AdPlatformCard'
import facebookIcon from '../../../assets/fb.png'
import {
  AdManagerAccountsByStoreIdDocument,
  useGenerateFacebookLinkMutation,
  useDisconnectAdPlatformMutation,
  Platform,
  AdPlatformAccountsByStoreIdDocument,
  CampaignsByAdManagerAccountIdsDocument,
  AdsByAdManagerAccountIdsDocument,
  AdsetsByAdManagerAccountIdsDocument
} from '../../../__generated__/graphql'
import { useContext, useEffect } from 'react'
import { JwtContext } from '../../../contexts/jwtContext'
import { isConnectedToFacebook } from '../../../Router'
import { client } from '../../../graphql/client'
import { GlobalStateContext } from '../../../contexts/GlobalStateContext'

export const ConnectAdPlatform = () => {
  const [generateFacebookLink, { data }] = useGenerateFacebookLinkMutation()
  const [disconnectAdPlatform, { loading: disconnectLoading, data: disconnectData }] = useDisconnectAdPlatformMutation()
  const decodedToken = useContext(JwtContext)
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService
  const resetToDefaultContext = () => send({ type: 'RESET_TO_DEFAULT_EVENT' })

  const connectToFacebookAdPlatform = () => {
    const interval = setInterval(async () => {
      const generateFacebookLinkResponse = 
      await generateFacebookLink({
        variables: {
          id: decodedToken?.stores[0] ?? '',
        },
        refetchQueries: [
          {
            query: AdManagerAccountsByStoreIdDocument,
            variables: {
              id: decodedToken?.stores[0] ?? '',
            },
          },
          {
            query: AdPlatformAccountsByStoreIdDocument,
            variables: {
              id: decodedToken?.stores[0] ?? '',
            },
          },
          {
            query: CampaignsByAdManagerAccountIdsDocument,
            variables: {
              ids: [],
            },
          },
          {
            query: AdsByAdManagerAccountIdsDocument,
            variables: {
              ids: [],
            },
          },
          {
            query: AdsetsByAdManagerAccountIdsDocument,
            variables: {
              ids: [],
            },
          }
        ],
      })
      if (generateFacebookLinkResponse.data?.generateFacebookLinkByStoreId.connected) {
        isConnectedToFacebook(true)
        clearInterval(interval)
      }
    }, 5000)
    return interval
  }


  useEffect(() => {
    generateFacebookLink({
      variables: {
        id: decodedToken?.stores[0] ?? '',
      },
    })
    const interval = connectToFacebookAdPlatform()
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (disconnectData?.disconnectAdPlatformByStoreId.__typename === 'DisconnectAdPlatform') {
      if (disconnectData.disconnectAdPlatformByStoreId.disconnected) {
        client.clearStore();
        resetToDefaultContext();
        generateFacebookLink({
          variables: {
            id: decodedToken?.stores[0] ?? '',
          },
        })
        const interval = connectToFacebookAdPlatform()
        return () => {
          clearInterval(interval)
        }
      }
    }
  }, [disconnectData])

  return (
    <Space size={'large'} direction="vertical" style={{ width: '100%' }}>
      <AdPlatformCard
        title="Connect your Facebook ad accounts"
        loading={disconnectLoading}
        connectionLink={data?.generateFacebookLinkByStoreId.oauthLink}
        onDisconnect={() => {
          isConnectedToFacebook(false)
          disconnectAdPlatform({
            variables: {
              id: decodedToken?.stores[0] ?? '',
              platform: Platform.Facebook,
            },
          })
        }}
        isConnected={data?.generateFacebookLinkByStoreId.connected ?? false}
        icon={facebookIcon}
      ></AdPlatformCard>
    </Space>
  )
}
