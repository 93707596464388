import { Input as _Input } from "antd";
import styled from "styled-components";

export const Input = styled(_Input)`
  height: 62px;
  border-radius: 15px;
`;

export const InputPassword = styled(_Input.Password)`
  height: 62px;
  border-radius: 15px;
`;