import { LocalStorageKeys } from '../common/types/localStorage'

window.addEventListener('storage', (event) => {
  if (event.key && Object.values(LocalStorageKeys).includes(event.key as LocalStorageKeys)) {
    window.dispatchEvent(new Event(`storage:${event.key}`))
  }
})

export const localStorageGetItem = (key: LocalStorageKeys) => {
  return localStorage.getItem(key)
}

export const localStorageSetItem = (key: LocalStorageKeys, value: string) => {
  localStorage.setItem(key, value)
  window.dispatchEvent(new Event(`storage:${key}`))
}

export const localStorageRemoveItem = (key: LocalStorageKeys) => {
  localStorage.removeItem(key)
  window.dispatchEvent(new Event(`storage:${key}`))
}

export const localStorageAddListener = (key: LocalStorageKeys, callback: () => void) => {
  window.addEventListener(`storage:${key}`, callback)
}

export const localStorageRemoveListener = (key: LocalStorageKeys, callback: () => void) => {
  window.removeEventListener(`storage:${key}`, callback)
}
