export enum LocalStorageKeys {
  Email = 'loginEmail',
  RememberMe = 'loginRememberMe',
  AuthToken = 'authToken',
  CodeEmail = 'codeEmail',
  CodePhone = 'codePhone',
  CodeChannel = 'codeChannel',
  CodeSentAt = 'codeSentAt',
  CodeExpiresAt = 'codeExpiresAt',
  PersistedState = 'persistedState',
  SiderCollapse = 'siderCollapse',
  Timezone = 'timezone',
  IsMobile = 'isMobile',
}

export enum LocalStorageTypes {
  String = 'string',
  Boolean = 'boolean',
  Number = 'number',
  Json = 'json',
}
