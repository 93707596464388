import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, split } from '@apollo/client'
import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { clearPrivateStorageLogout, decodeToken } from '../services/auth'
import { getMainDefinition } from '@apollo/client/utilities'
import { privateRoutes } from '../routes/privateRoutes'
import { LocalStorageKeys } from '../common/types/localStorage'
import { localStorageGetItem, localStorageSetItem } from '../services/storage'

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_GRAPHQL_WS_SERVER_URL,
    connectionParams: () => ({
      Authorization: localStorageGetItem(LocalStorageKeys.AuthToken),
    }),
  }),
)

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_BASE_SERVER_URL}/graphql`,
  credentials: 'same-origin',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorageGetItem(LocalStorageKeys.AuthToken)

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
    credentials: 'include',
  }
})

// our custom "afterware" that checks each response and saves the sessionID
// if it contains an 'Authorization' header
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext()
    const newToken = context.response.headers.get('Authorization')?.replace('Bearer ', '')
    const currentToken = localStorageGetItem(LocalStorageKeys.AuthToken)

    const hasErrors = (response?.errors?.length ?? 0) > 0
    const isUnauthorized = response?.errors?.some((err) => err?.message.toLowerCase() === 'not authorised!')
    const isTokenInvalid = !newToken || (decodeToken(newToken)?.exp ?? 0) * 1000 < Date.now()
    const isPrivateRoute = privateRoutes.some((route) => window.location.href.startsWith(route.path ?? ''))

    if (hasErrors && isUnauthorized && isTokenInvalid && isPrivateRoute) {
      clearPrivateStorageLogout()
      window.location.href = '/login'
    } else if (newToken && newToken !== currentToken) {
      localStorageSetItem(LocalStorageKeys.AuthToken, newToken)
    }
    return response
  })
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  authLink.concat(afterwareLink.concat(httpLink)),
)

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})
