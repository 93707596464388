import { useContext, useEffect, useMemo } from 'react'
import { Typography, theme, TreeSelect as _TreeSelect, Avatar, Space } from 'antd'
import styled from 'styled-components'
import { Key, useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import facebookIcon from '../../../assets/fb.png'
import { useSelector } from '@xstate/react'
import { GlobalStateContext } from '../../../contexts/GlobalStateContext'
import { DashboardMachineState } from '../../../machines/dashboardMachine/dashboardMachine'
import { JwtContext } from '../../../contexts/jwtContext'
import { useAdManagerAccountsByStoreIdLazyQuery } from '../../../__generated__/graphql'
import './style.css'
interface AdPlatformAccount {
  accounts: {
    id: string
    sourceAdManagerAccountId: string
    title: string
  }[]
  icon: string
  name: string
}
const { Text } = Typography

const { Group: AvatarGroup } = Avatar

const DropdownWrapper = styled.div`
  padding: 0 8px;
  .ant-select-tree-checkbox {
    margin-block-start: 8px !important;
  }
  .ant-select-tree-list-holder-inner > div::before {
    content: '';
    height: 36px;
  }
  .ant-select-tree-treenode {
    width: 100%;
  }
  .ant-select-tree-treenode > :nth-child(2) {
    order: 1 !important;
  }
  .ant-select-tree-switcher {
    transform: translate(0, 6px);
  }
  .ant-select-tree-treenode {
    align-items: center !important;
  }
  .ant-select-tree-node-content-wrapper:hover {
    background: none !important;
  }
`

const TreeSelect = styled(_TreeSelect)`
  height: 40px;
  .ant-select-selector {
    border-radius: 12px !important;
  }
  .ant-select-selection-item {
    background: white !important;
    border: none !important;
    padding: 2px !important;
  }
`
const AdPlatformLabel = styled(Text)`
  font-weight: 600;
  font-size: 17px;
`
const AdPlatformSub = styled(Text)`
  font-weight: 400;
  font-size: 17px;
  color: ${(props) => props.color};
`

const AdPlatformIcon = styled.img`
  width: 32px;
  vertical-align: middle;
`

const AdAccount = styled(Space)`
  & > * {
    height: 18px;
  }
`

const AdAccountTitle = styled(Text)`
  font-weight: 600;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`

const AdAccountSubtitle = styled(Text)`
  font-weight: 400;
  font-size: 13px;
  color: ${(props) => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`
const adAccountsSelector = (state: DashboardMachineState) => state.context.adAccounts

export const AdPlatformAction = () => {
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService
  const [adAccountData, setAdAccountData] = useState<AdPlatformAccount[] | undefined>([])
  const adAccounts = useSelector(globalServices.dashboardService, adAccountsSelector)

  const setAdAccounts = (adAccounts: string[]) => send({ type: 'SET_AD_ACCOUNTS_EVENT', adAccounts })
  const decodedToken = useContext(JwtContext)
  const [getAdManagerAccounts, { data }] = useAdManagerAccountsByStoreIdLazyQuery()
  useEffect(() => {
    getAdManagerAccounts({
      variables: {
        id: decodedToken?.stores[0] ?? '',
      },
      fetchPolicy: 'network-only'
    })
  }, [decodedToken])
  useEffect(() => {
    if (data && (data.adManagerAccountsByStoreId?.length || 0) > 0) {
      const adManagerAccounts = [
        {
          icon: facebookIcon,
          name: 'Facebook',
          accounts: (data.adManagerAccountsByStoreId || []).map(({ id, name, sourceAdManagerAccountId }) => ({
            id: id,
            sourceAdManagerAccountId: sourceAdManagerAccountId,
            title: name,
          })),
        },
      ]
      setAdAccountData(adManagerAccounts)
      if (adAccounts.length > 0) {
        const validAdAccounts = adAccounts.filter((accountId) => data.adManagerAccountsByStoreId?.some((account) => account.id === accountId))
        setAdAccounts(validAdAccounts)
      } else {
        setAdAccounts(data.adManagerAccountsByStoreId?.map((account) => account.id) ?? [])
      }
    }
  }, [data])

  const {
    token: { colorTextTertiary },
  } = theme.useToken()

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])

  const handleExpand = (expandedKeys: Key[]) => {
    const latestExpandedKey = expandedKeys.pop()
    if (latestExpandedKey) {
      setExpandedKeys([latestExpandedKey])
    } else {
      setExpandedKeys([])
    }
  }
  const handleChange = (newValue: unknown) => {
    setAdAccounts(newValue as string[])
  }

  const icons = useMemo(() => {
    const foundIcons = new Set<string>()
    adAccounts.forEach((id: string) => {
      adAccountData?.forEach((obj) => {
        obj.accounts.forEach((account) => {
          if (account.id === id) {
            foundIcons.add(obj.icon)
          }
        })
      })
    })
    return Array.from(foundIcons)
  }, [adAccountData, adAccounts])

  

  const treeData = adAccountData?.map(({ name, icon, accounts }) => {
    return {
      key: name, // Assigning 'name' as the key for the top-level node
      value: name,
      title: (
        <Space align="center" direction="horizontal">
          <AdPlatformIcon src={icon} />
          <AdPlatformLabel>
            {name} <AdPlatformSub color={colorTextTertiary}>({accounts.length})</AdPlatformSub>
          </AdPlatformLabel>
        </Space>
      ),
      children: accounts.map(({ id, sourceAdManagerAccountId, title }) => {
        return {
          value: id,
          key: id,
          title: (
            <AdAccount direction="vertical" size={0}>
              <AdAccountTitle>{title}</AdAccountTitle>
              <AdAccountSubtitle color={colorTextTertiary}>ID: {sourceAdManagerAccountId}</AdAccountSubtitle>
            </AdAccount>
          ),
        }
      }),
    }
  })

  return (
    <TreeSelect
      popupClassName="popup-class"
      treeIcon={true}
      size="large"
      showSearch={false}
      onTreeExpand={handleExpand}
      treeExpandedKeys={expandedKeys}
      treeCheckable={true}
      maxTagCount={0}
      maxTagPlaceholder={
        icons.length > 0 && (
          <Space style={{ padding: '0 4px' }} align="center" direction="horizontal" size={0}>
            {icons.length > 0 && (
              <AvatarGroup style={{ marginRight: 8 }}>
                {icons.map((icon, index) => (
                  <Avatar key={index} size={20} src={icon} />
                ))}
              </AvatarGroup>
            )}
            <AdPlatformLabel>
              {adAccounts?.length} Ad Account{adAccounts?.length === 1 ? '' : 's'}
            </AdPlatformLabel>
          </Space>
        )
      }
      switcherIcon={<DownOutlined />}
      style={{ width: '200px' }}
      value={adAccountData && adAccountData.length > 0 ? adAccounts : []}
      dropdownStyle={{ maxHeight: 'auto', minWidth: '300px' }}
      placeholder="Select Ad Account(s)"
      allowClear
      multiple
      dropdownRender={(node) => {
        return <DropdownWrapper>{node}</DropdownWrapper>
      }}
      treeDefaultExpandAll
      onChange={handleChange}
      treeData={treeData}
    />
  )
}
