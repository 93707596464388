import { Card, Row, Space, Typography, Button } from 'antd'
import { ProfileAction } from '../../components/HeaderActions/Profile'
import { SetupOurPixel } from '../QuickStart/SetupOurPixel'
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout'
import { usePixelConfigurationStatusByStoreIdQuery } from '../../__generated__/graphql'
import { useContext, useEffect, useState } from 'react'
import { JwtContext } from '../../contexts/jwtContext'
import { useDayjs } from '../../hooks/useDayjsHook'
import styled from 'styled-components'

const RefreshButton = styled(Button)`
  border: 3px solid #2a85ff;
  color: #2a85ff;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #2a85ff !important;
    color: #ffffff !important;
    border: 3px solid #2a85ff !important;
  }
`

export const SetupTracking = () => {
  const { dayjs } = useDayjs()
  const decodedToken = useContext(JwtContext)
  const storeId = decodedToken?.stores[0] ?? ''
  const [polling, setPolling] = useState(true)
  const [timeElapsed, setTimeElapsed] = useState(0)

  const { data, startPolling, stopPolling } = usePixelConfigurationStatusByStoreIdQuery({
    variables: {
      id: storeId,
    },
    pollInterval: polling ? 5000 : 0,
  })

  useEffect(() => {
    if (polling) {
      const interval = setInterval(() => {
        setTimeElapsed((prev) => prev + 1)
      }, 1000)

      if (timeElapsed >= 600) {
        setPolling(false)
        stopPolling()
      }

      return () => clearInterval(interval)
    }
  }, [polling, timeElapsed, stopPolling])

  const handleRefresh = () => {
    setTimeElapsed(0)
    setPolling(true)
    startPolling(5000)
  }

  const pixelConfigStatus = data?.pixelConfigurationStatusByStoreId.__typename === 'PixelConfigurationStatus' ? data.pixelConfigurationStatusByStoreId : null
  return (
    <ResponsiveSiderLayout title="Setup Tracking" primaryPageKey="setup-tracking" actions={<ProfileAction />} mobileActions={[<ProfileAction key={4} />]}>
      <Card style={{ margin: '40px' }}>
        <Space direction="vertical">
          <Row>
            <Typography.Text style={{ fontWeight: 700, fontSize: 20 }}>
              Pixel Status: <span style={{ color: pixelConfigStatus?.connected ? (polling ? '#46C454' : '#BCBCBC') : '#BCBCBC' }}>{pixelConfigStatus?.connected ? 'Active' : 'Inactive'}</span>
            </Typography.Text>
          </Row>
          <Row>
            <Typography.Text style={{ fontSize: 16 }}>
              {pixelConfigStatus?.connected ? ` Last Event: ${dayjs(pixelConfigStatus?.lastUpdatedAt).format('MM/DD/YY h:mm A')}` : `After setup visit your site to test the pixel`}
            </Typography.Text>
          </Row>
          {(!polling) && (
            <Row>
              <RefreshButton onClick={handleRefresh}>
                Refresh
              </RefreshButton>
            </Row>
          )}
        </Space>
      </Card>
      <Card style={{ margin: '40px', padding: '24px' }}>
        <SetupOurPixel />
      </Card>
    </ResponsiveSiderLayout>
  )
}
