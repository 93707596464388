import { Row as _Row } from "antd";
import { PasswordResetLayout } from "../../layouts/PasswordResetLayout";
import { VerifyAccountForm } from "../../components/VerifyAccountForm";
export const VerifyAccount = () => {
  return (
    <PasswordResetLayout>
      <VerifyAccountForm />
    </PasswordResetLayout>
  );
};
