import React from 'react'

type CSSProperties = React.CSSProperties

const styles: { [key: string]: CSSProperties } = {
  body: {
    fontFamily: 'Arial, sans-serif',
    color: '#000000',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
  },
  h1: {
    fontSize: '26pt',
    color: '#000000',
  },
  h2: {
    fontSize: '20pt',
    color: '#000000',
  },
  p: {
    fontSize: '11pt',
    lineHeight: 1.5,
  },
  li: {
    fontSize: '11pt',
    lineHeight: 1.5,
  },
  ul: {
    listStyleType: 'disc',
    paddingLeft: '20px',
  },
  innerUl: {
    listStyleType: 'circle',
    paddingLeft: '20px',
  },
}

const FoxTraxRefundPolicy: React.FC = () => {
  return (
    <div style={styles.body}>
      <h1 style={styles.h1}>FoxTrax Refund Policy</h1>

      <p style={styles.p}>At FoxTrax, we prioritize customer satisfaction. We understand...</p>

      <h2 style={styles.h2}>30-Day Money-Back Guarantee</h2>

      <p style={styles.p}>We offer a 30-day money-back guarantee on all purchases...</p>

      <h2 style={styles.h2}>Refund Request Procedure</h2>

      <p style={styles.p}>To request a refund, please follow these steps:</p>

      <ul style={styles.ul}>
        <li style={styles.li}>Contact our support team at support@foxtrax.io, and include the following information:</li>
        <ul style={styles.innerUl}>
          <li style={styles.li}>Your order or invoice number</li>
          <li style={styles.li}>The email address used during the purchase</li>
          <li style={styles.li}>The reason for requesting a refund</li>
        </ul>
        <li style={styles.li}>After reviewing your refund request, our team will send you an email confirmation of receipt.</li>
        <li style={styles.li}>If your request is approved, the refund will be processed, and the funds will be credited back to the original payment method within 5-10 business days.</li>
      </ul>

      <h2 style={styles.h2}>Terms and Conditions</h2>

      <ul style={styles.ul}>
        <li style={styles.li}>
          The 30-day money-back guarantee only applies to the first-time purchase of our software. If you've purchased our software before, you may not be eligible for a refund on subsequent
          purchases.
        </li>
        <li style={styles.li}>All services attached to the software, such as extra support or customization, are not subject to a refund.</li>
        <li style={styles.li}>Our refund policy does not cover purchases made through other platforms or from third-party vendors.</li>
      </ul>

      <p style={styles.p}>Please note that we reserve the right to decline a refund request if it is found to be fraudulent, repetitive, or if there is a violation of our terms and conditions.</p>

      <p style={styles.p}>By making a purchase at FoxTrax, you agree to our refund policy.</p>

      <h2 style={styles.h2}>Contact</h2>

      <p style={styles.p}>If you have any questions or concerns regarding our refund policy, please contact us at support@foxtrax.io.</p>
    </div>
  )
}

export default FoxTraxRefundPolicy
