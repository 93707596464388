import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import UtmWarning from '../../../assets/quickstart_assets/utm_warning.jpg'
import { Button } from '../../../components/StyledComponents'

const ModalTitle = styled.span`
  font-size: 28px;
  font-weight: bold;
  color: black;
`
const WarningText = styled.span`
  color: red;
`
const UtmImage = styled.img`
  width: 100%;
  padding: 30px 0px 30px 0px;
`
const UtmParagraph = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`

interface UTMWarningModalProps {
  isOpen: boolean
  onClose: () => void
}

const UTMWarningModal: React.FC<UTMWarningModalProps> = ({ isOpen: isOpen, onClose }) => {
  return (
    <Modal
      style={{ left: 100, zIndex: 100 }} // Adjusted z-index to ensure it's above the sider
      title={<ModalTitle><WarningText>WARNING:</WarningText> Tracking doesn't work without UTMs</ModalTitle>}
      onOk={onClose}
      footer={[
        <Button type="primary" block key="submit" onClick={onClose}>
          Ok Got It
        </Button>,
      ]}
      closable={false}
      open={isOpen}
    >
      <UtmImage src={UtmWarning} alt="UTM Parameter" />
      <UtmParagraph>After hooking up your Pixel. Setup your UTMs here.</UtmParagraph>
    </Modal>
  )
}

export default UTMWarningModal
