import React from 'react'
type CSSProperties = React.CSSProperties

const FoxTraxPrivacyPolicy: React.FC = () => {
  const styles: { [key: string]: CSSProperties } = {
    body: {
      fontFamily: 'Arial, sans-serif',
      lineHeight: 1.6,
      padding: '2rem',
      color: '#000000',
      maxWidth: '800px',
      margin: 'auto',
      backgroundColor: '#ffffff',
    },
    h1: {
      fontSize: '26pt',
      marginBottom: '0.2rem',
    },
    h2: {
      fontSize: '20pt',
      margin: '1.5rem 0 0.5rem',
    },
    h2Subtitle: {
      fontSize: '20pt',
      color: '#666666',
      marginBottom: '1rem',
    },
    p: {
      fontSize: '11pt',
      margin: '0.5rem 0',
      textAlign: 'justify',
    },
    li: {
      fontSize: '11pt',
      margin: '0.5rem 0',
      textAlign: 'justify',
    },
    highlight: {
      fontWeight: 'bold',
    },
  }

  return (
    <div style={styles.body}>
      <h1 style={styles.h1}>FoxTrax Privacy Policy</h1>
      <h2 style={{ ...styles.h2, ...styles.subtitle }}>Last Updated: 8/2/2023</h2>
      <p style={styles.p}>
        At FoxTrax, we are committed to protecting your privacy and handling your data in the way that you would want us to, in compliance with the General Data Protection Regulation (GDPR) and other
        relevant data protection laws in Europe.
      </p>
      <h2 style={styles.h2}>1. Data Controller</h2>
      <p style={styles.p}>
        FoxTrax is the data controller of the personal data collected via our software service. We are responsible for ensuring that your data is handled in compliance with the GDPR and other relevant
        data protection laws.
      </p>
      <h2 style={styles.h2}>2. Information We Collect</h2>
      <p style={styles.p}>We may collect and process the following categories of personal data:</p>
      <ul>
        <li style={styles.li}>Personal Identification Information: Full name, email address, contact details.</li>
        <li style={styles.li}>Business Information: Online store details, ad metrics, financial reporting data.</li>
        <li style={styles.li}>Technical Data: IP addresses, browser type, operating system, other technology on the devices you use to access our service.</li>
      </ul>
      <h2 style={styles.h2}>3. Lawful Basis for Processing</h2>
      <p style={styles.p}>We process your personal data on the following legal bases:</p>
      <ul>
        <li style={styles.li}>The provision and improvement of our services.</li>
        <li style={styles.li}>Compliance with legal obligations.</li>
        <li style={styles.li}>Where we have received explicit consent.</li>
      </ul>
      <h2 style={styles.h2}>4. How We Use Your Information</h2>
      <p style={styles.p}>We use the information we collect to provide our services, improve our platform, conduct marketing activities, and respond to your inquiries or support requests.</p>
      <h2 style={styles.h2}>5. Sharing Your Personal Information</h2>
      <p style={styles.p}>We will not share your personal information with third parties without your consent, except as necessary to provide our services or comply with legal obligations.</p>
      <h2 style={styles.h2}>6. International Data Transfers</h2>
      <p style={styles.p}>
        As part of providing our services, your personal data may be transferred to, and stored at, a destination outside the European Economic Area ("EEA"). We will take all steps reasonably
        necessary to ensure that your data is treated securely and in accordance with this privacy policy.
      </p>

      <h2 style={styles.h2}>7. Data Retention</h2>
      <p style={styles.p}>
        We will store your personal data only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting
        requirements.
      </p>
      <h2 style={styles.h2}>8. Security</h2>
      <p style={styles.p}>
        We are committed to ensuring that your information is secure. We have implemented appropriate technical and organizational measures to protect your personal data from accidental loss and from
        unauthorized access, use, alteration, or disclosure.
      </p>
      <h2 style={styles.h2}>9. Your Rights</h2>
      <p style={styles.p}>
        Under the GDPR, you have various rights in relation to your personal data, such as the rights of access, rectification, restriction, objection, portability, and erasure. Please contact us if
        you wish to exercise these rights.
      </p>
      <h2 style={styles.h2}>10. Changes to This Policy</h2>
      <p style={styles.p}>We reserve the right to modify this privacy policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      <h2 style={styles.h2}>11. Contact Us</h2>
      <p style={styles.p}>
        If you have any questions about this Privacy Policy or your rights under the GDPR, please contact us at: <a href="mailto:support@foxtrax.io">support@foxtrax.io</a>
      </p>
    </div>
  )
}
export default FoxTraxPrivacyPolicy
