import { useAdPlatformAccountsByStoreIdQuery, useDownloadProgressByStoreIdQuery, useProgressSubscription } from '../../__generated__/graphql'
import { useContext, useEffect, useState } from 'react'
import { JwtContext } from '../../contexts/jwtContext'
import { ProgressCard } from './ProgressCard/ProgressCard'
import { isConnectedToFacebook } from '../../Router'
import { useReactiveVar } from '@apollo/client'

type LoaderState = {
  loaderVisible: boolean
  downloadProgress: { entities: number; metrics: number }
}

export const ProgressContainer = () => {
  const decodedToken = useContext(JwtContext)
  const isConnectedToFacebookVar = useReactiveVar(isConnectedToFacebook)
  const [loaderState, setLoaderState] = useState<LoaderState>({
    loaderVisible: false,
    downloadProgress: { entities: 0, metrics: 0 },
  })

  const updateLoader = (downloadProgress: { entities: number; metrics: number }) => {
    setLoaderState((_) => ({
      loaderVisible: ((downloadProgress.entities >= 1 && downloadProgress.metrics >= 0 && downloadProgress.entities <= 100 && downloadProgress.metrics < 100) && isConnectedToFacebook()),
      downloadProgress: downloadProgress,
    }))
  }

  const storeId = decodedToken?.stores[0] ?? ''
  const { data: adPlatformData } = useAdPlatformAccountsByStoreIdQuery({
    variables: {
      id: storeId,
    },
  })

  const adPlatformAccountId = adPlatformData?.adPlatformAccountsByStoreId?.[0]?.id ?? ''

  useEffect(() => {
    if (!isConnectedToFacebookVar) {
      isConnectedToFacebook(false)
      updateLoader({ entities: 0, metrics: 0 })
    }
  }, [isConnectedToFacebookVar])
  
  useDownloadProgressByStoreIdQuery({
    variables: {
      storeId: storeId,
    },
    onCompleted: ({ downloadProgressByStoreId }) => {
      if (downloadProgressByStoreId) {
        const newDownloadProgress = {
          entities: downloadProgressByStoreId.entities ?? 0,
          metrics: downloadProgressByStoreId.metrics ?? 0,
        }
        setLoaderState((prevState: LoaderState) => ({
          ...prevState,
          downloadProgress: newDownloadProgress,
        }))
        isConnectedToFacebook(downloadProgressByStoreId.connected ?? false)
        updateLoader(newDownloadProgress)
      }
    },
  })

  useProgressSubscription({
    variables: {
      progressId: adPlatformAccountId,
    },
    onError: (error) => {
      console.error('Error subscribing to progress', error)
    },
    onData: ({ data: subscriptionData }) => {
      const progress = subscriptionData.data?.progress
      if (progress?.__typename === 'LoadingProgress') {
        setLoaderState((prevState: LoaderState) => {
          const newDownloadProgress = {
            entities: progress.entities ?? prevState.downloadProgress.entities,
            metrics: progress.metrics ?? prevState.downloadProgress.metrics,
          }
          updateLoader(newDownloadProgress)
          return {
            ...prevState,
            downloadProgress: newDownloadProgress,
          }
        })
      }
    },
  })

  return loaderState.loaderVisible ? <ProgressCard initialProgress={loaderState.downloadProgress} /> : <></>
}
