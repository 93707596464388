import React, { useState, useRef, useEffect } from 'react'
import { Input, Popover, Space } from 'antd'
import styled from 'styled-components'
import { AttributionTableTabs } from '../../machines/dashboardMachine/dashboardMachine'
import { SearchOption } from './SearchBar'

const StyledInput = styled(Input)`
  margin: 2px;
  min-width: 200px;
  width: fit-content;
`

const SearchValueSpan = styled.span`
  background-color: #e6f7ff;
  color: #1890ff;
  border-radius: 4px;
  padding: 2px 6px;
  display: inline-flex;
  align-items: center;
`

const OptionsContainer = styled.div`
  &:hover > div {
    background-color: transparent;
  }
`

const OptionItem = styled.div<{ isActive: boolean; isDisabled: boolean }>`
  padding: 6px 10px;
  border-radius: 6px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  background-color: ${props => (props.isActive ? '#f5f5f5' : 'transparent')};
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  
  &:hover {
    background-color: ${props => (props.isDisabled ? 'transparent' : '#f5f5f5')};
  }

  ${OptionsContainer}:hover &:hover {
    background-color: ${props => (props.isDisabled ? 'transparent' : '#f5f5f5')};
  }
`

export interface SearchBarTag {
  key: AttributionTableTabs
  label: React.ReactNode
  value: string
}

interface SearchSelectProps {
  options: SearchOption[]
  focus: boolean
  currentTab: AttributionTableTabs
  onFocus: () => void
  onBlur: () => void
  onSelect: (option: SearchBarTag) => void
  onBackspace: () => void
}

export const SearchSelect = ({ options, focus, currentTab, onFocus, onBlur, onSelect, onBackspace }: SearchSelectProps) => {
  const [search, setSearch] = useState('')
  const [activeIndex, setActiveIndex] = useState(options.findIndex(option => option.key === currentTab))
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (inputRef.current) {
      if (focus) inputRef.current.focus()
      else setSearch('')
    }
  }, [focus])

  useEffect(() => {
    setActiveIndex(options.findIndex(option => option.key === currentTab))
  }, [currentTab])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearch(value)
    if (value === '') {
      setActiveIndex(options.findIndex(option => option.key === currentTab))
    }
  }

  const handleSelect = (option: SearchOption) => {
    onSelect({ key: option.key, label: option.label, value: search })
    setSearch('')
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && search === '') {
      onBackspace()
    } else if (event.key === 'ArrowDown') {
      event.preventDefault()
      setActiveIndex((prevIndex) => (prevIndex + 1) % options.length)
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      setActiveIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length)
    } else if (event.key === 'Enter' && search !== '') {
      event.preventDefault()
      handleSelect(options[activeIndex])
    }
  }

  const content = (
    <OptionsContainer>
      {options.map((option, index) => (
        <OptionItem
          key={option.key}
          isActive={search === '' ? option.key === currentTab : index === activeIndex}
          isDisabled={search === ''}
          onClick={() => handleSelect(option)}
        >
          <Space align="center" style={{ width: '100%', minHeight: '26px' }}>
            {option.label}
            {search && <SearchValueSpan>{search}</SearchValueSpan>}
          </Space>
        </OptionItem>
      ))}
    </OptionsContainer>
  )

  return (
    <Popover
      content={content}
      trigger="click"
      open={focus}
      placement="bottomLeft"
      arrow={false}
    >
      <StyledInput
        ref={inputRef}
        placeholder="Search..."
        value={search}
        onChange={handleSearch}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        variant="borderless"
      />
    </Popover>
  )
}
