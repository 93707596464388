import React from 'react'
import { Layout, theme, Typography, Space as _Space } from 'antd'
import styled from 'styled-components'
const { Title } = Typography

const { Header: _Header } = Layout

const Space = styled(_Space)`
  .ant-space-item {
    display: flex;
  }
`
const Header = styled(_Header)`
  z-index: 10 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const PageTitle = styled(Title)`
  margin: 0 !important;
  padding-left: 10px;
  word-break: initial;
  font-weight: 600 !important;
`
interface AppHeaderProps {
  title: string
  actions?: React.ReactNode[]
  firstName?: string
  lastName?: string
  logo?: string
}

export const AppHeader = ({ title, actions, logo }: AppHeaderProps) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <Header style={{ height: 'auto', padding: '16px 16px 16px 0px', background: colorBgContainer, position: 'sticky', top: 0, zIndex: 1, width: '100%', flexWrap: 'wrap' }}>
      <Space direction="horizontal" size="small">
        {logo && (
          <div style={{ textAlign: 'center', display: 'flex', padding: '14px 14px 0 0' }}>
            <img src={logo} alt="company logo" style={{ height: '40px', width: '40px' }} />
          </div>
        )}
        <PageTitle level={3}>{title}</PageTitle>
      </Space>
      <Space align="center" direction="horizontal" size="middle">
        {actions}
      </Space>
    </Header>
  )
}
