import { Navigate, RouteObject, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '../pages/ErrorBoundary'
import { SendCode } from '../pages/SendCode'
import { VerifyCode } from '../pages/VerifyCode'
import { ResetPassword } from '../pages/ResetPassword'
import { Routes } from '../Routes'

import FoxTraxTerms from '../pages/Legal/TermsOfService'
import FoxTraxPrivacyPolicy from '../pages/Legal/PrivacyPolicy'
import FoxTraxRefundPolicy from '../pages/Legal/RefundPolicy'
import Legal from '../pages/Legal/Legal'
import { useContext } from 'react'
import { JwtContext } from '../contexts/jwtContext'
import { VerifyAccount } from '../pages/VerifyAccount'

const PasswordResetWrapper = ({ children }: { children: React.ReactNode }) => {
  const decodedToken = useContext(JwtContext);
  const location = useLocation();
  if (decodedToken) {
    if (location.pathname === Routes.PASSWORD_RESET) {
      return <Navigate to={Routes.PASSWORD_RESET_SEND_CODE} replace />;
    }
    if (location.pathname === Routes.PASSWORD_RESET_VERIFY_ACCOUNT) {
      return <Navigate to={`${Routes.PASSWORD_RESET}/send-code`} replace />;
    }
  } else {
    if (location.pathname === Routes.PASSWORD_RESET) {
      return <Navigate to={Routes.PASSWORD_RESET_VERIFY_ACCOUNT} replace />;
    }
    if (location.pathname !== Routes.PASSWORD_RESET_VERIFY_ACCOUNT) {
      return <Navigate to={Routes.PASSWORD_RESET_VERIFY_ACCOUNT} replace />;
    } 
  }
  return <>{children}</>;
};

export const sharedRoutes: RouteObject[] = [
  {
    path: Routes.PASSWORD_RESET_NEW_PASSWORD,
    element: <ResetPassword />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.PASSWORD_RESET_VERIFY_CODE,
    element: <VerifyCode />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.PASSWORD_RESET_SEND_CODE,
    element: <SendCode />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.PASSWORD_RESET,
    element: <PasswordResetWrapper><VerifyAccount /></PasswordResetWrapper>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.PASSWORD_RESET_VERIFY_ACCOUNT,
    element: <PasswordResetWrapper><VerifyAccount /></PasswordResetWrapper>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: Routes.LEGAL,
    element: null,
    children: [
      {
        path: Routes.TERMS_OF_SERVICE,
        element: <Legal><FoxTraxTerms/></Legal>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: Routes.PRIVACY_POLICY,
        element: <Legal><FoxTraxPrivacyPolicy/></Legal>,
        errorElement: <ErrorBoundary />,
      },
      {
        path: Routes.REFUND_POLICY,
        element: <Legal><FoxTraxRefundPolicy/></Legal>,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]
