import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { privateRoutes } from "./routes/privateRoutes"
import { publicRoutes } from "./routes/publicRoutes"
import { sharedRoutes } from "./routes/sharedRoutes";
import { makeVar } from "@apollo/client/cache/inmemory/reactiveVars";

export const isConnectedToFacebook = makeVar(false)

const router = createBrowserRouter([...privateRoutes, ...publicRoutes, ...sharedRoutes])

function Router() {
  return (
    <RouterProvider router={router} />
  );
}

export default Router;
