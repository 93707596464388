/* eslint-disable no-restricted-imports */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useTimezoneSettingQuery } from '../__generated__/graphql'
import dayjs, { Dayjs } from 'dayjs'
import timezonedDayjs from '../services/dayjs'
import { LocalStorageKeys } from '../common/types/localStorage'
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from '../services/storage'

interface TimezonedDayjsContext {
  currentTimezone: string | undefined
}

const TimezoneContext = createContext<TimezonedDayjsContext | null>(null)

interface TimezoneProviderProps {
  children: React.ReactNode
}

export const TimezoneProvider = ({ children }: TimezoneProviderProps) => {
  const { data } = useTimezoneSettingQuery()
  const newTimezone = data?.viewer?.timezone

  const [currentTimezone, setCurrentTimezone] = useState<string | undefined>(() => {
    const initialTimezone = localStorageGetItem(LocalStorageKeys.Timezone) || undefined
    dayjs.tz.setDefault(initialTimezone)
    return initialTimezone
  })

  useEffect(() => {
    if (newTimezone !== currentTimezone) {
      dayjs.tz.setDefault(newTimezone ?? undefined)
      setCurrentTimezone(newTimezone ?? undefined)
      if (newTimezone !== null && newTimezone !== undefined) {
        localStorageSetItem(LocalStorageKeys.Timezone, newTimezone)
      } else {
        localStorageRemoveItem(LocalStorageKeys.Timezone)
      }
    }
  }, [newTimezone])

  return <TimezoneContext.Provider value={{ currentTimezone }}>{children}</TimezoneContext.Provider>
}

/**
 *
 * // Using useDayjs hook
 * import { useDayjs } from '../../services/dayjs'
 *
 * const MyComponent = () => {
 *   const { dayjs, timezone } = useDayjs()
 *   return <div>Current time: { dayjs().format('YYYY-MM-DD HH:mm:ss')}, Current Timezone: {timezone} </div>
 * }
 *
 */
export const useDayjs = () => {
  const context = useContext(TimezoneContext)
  if (!context) {
    throw new Error('useDayjs must be used within a TimezoneProvider')
  }
  return { dayjs: timezonedDayjs, timezone: context.currentTimezone }
}
export { Dayjs }

/**
 * Usage:
 *
 * Wrap your app with TimezoneProvider
 * ```typescript
 * import { TimezoneProvider } from '../../services/dayjs'
 * const App = () => (
 *   <TimezoneProvider>
 *     <YourComponents />
 *   </TimezoneProvider>
 * )
 * ```
 *
 * To change timezone in the client update the server
 * and the useDayjs hook will rerender the components automatically.
 * ```typescript
 * const TimezoneChanger = () => {
 *   const [updateUser] = useUserMutation()
 *
 *   return <button onClick={() => updateUser({ variables: { input: { timezone: 'America/New_York' } } })}>Set to New York</button>
 * }
 ```
 */
