import React from "react"
import {
  Form,
  Typography,
  message,
} from "antd"
import { useResetUserPasswordByContextUserIdMutation } from "../../__generated__/graphql"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import {
  colorTextSecondary,
} from "../../theme/colors"
import {
  Spin,
  Card,
  Button,
  FormItem,
  InputPassword,
  BackButton,
} from "../StyledComponents"
import { Routes } from "../../Routes"
import arrowLeft from "../../assets/arrow_left.svg"

interface ResetPasswordFormValues {
    password: string
}

const _Text = Typography.Text
const Text = styled(_Text)`
  color: ${colorTextSecondary}
  font-size: 14px
  font-weight: 600
`

export const ResetPasswordForm = () => {
  const navigate = useNavigate()
  const [resetPasswordMutation, { loading }] = useResetUserPasswordByContextUserIdMutation({
    onCompleted: (data) => {
      switch (data?.resetUserPasswordByContextUserId?.__typename) {
        case "UnableToResetPassword":
          message.error(data?.resetUserPasswordByContextUserId?.error)
          break
        case "User":
          navigate(Routes.LOGIN)
          break
        default:
          message.error("An unknown error occurred")
          break
      }
    },
    onError: () => {
      message.error("An unknown error occurred")
    },
  })

  const validateVerifyPassword = ({
    getFieldValue,
  }: {
    getFieldValue: (field: string) => string
  }) => ({
    validator(_: unknown, value: string) { 
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve()
      }
      return Promise.reject(
        new Error("The two passwords that you entered do not match!")
      )
    },
  })

  const handleSubmit = async ({ password }: ResetPasswordFormValues) => {
    await resetPasswordMutation({
      variables: {
        password,
      },
    })
  }

  return (
    <>
      <Card>
        <BackButton
          onClick={() => {
            navigate(-1)
          }}
          src={arrowLeft}
        ></BackButton>
        <Typography.Title
          style={{ textAlign: "center", fontSize: "32px", fontWeight: 700 }}
          level={3}
        >
          Reset Password
        </Typography.Title>
        <Text>
          Input a new password, must be at least 8 characters.
        </Text>
        <Form
          style={{ marginTop: "32px" }}
          name="reset-password-form"
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <FormItem
            name="password"
            label="New Password"
            rules={[{ required: true, message: "Please input the password" }, { min: 8, message: 'Password should at least be 8 characters' }]}
          >
            <InputPassword placeholder="New Password" />
          </FormItem>
          <FormItem
            name="repeatPassword"
            label="Repeat New Password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              validateVerifyPassword,
            ]}
          >
            <InputPassword placeholder="Repeat New Password" />
          </FormItem>
          <FormItem>
            <Button
              disabled={loading}
              block
              type="primary"
              htmlType="submit"
            >
              {loading ? <Spin /> : "Save New Password"}
            </Button>
          </FormItem>
        </Form>
      </Card>
    </>
  )
}
