import {
  Form,
  Typography,
  message,
  Button as _Button,
  Space,
} from "antd"
import OtpInput from "react-otp-input"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import {
  colorPrimary,
  colorTextSecondary,
} from "../../theme/colors"
import { 
  Spin,
  Card,
  Button,
  FormItem,
  BackButton,
} from "../StyledComponents"
import { useEffect, useState } from "react"
import { Routes } from "../../Routes"
import { VerifyChannel, useSendCodeByEmailMutation, useVerifyCodeMutation } from "../../__generated__/graphql"
import { LocalStorageKeys } from "../../common/types/localStorage"
import arrowLeft from "../../assets/arrow_left.svg"
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from "../../services/storage"

interface VerifyCodeValues {
  code: string
}

const _Text = Typography.Text
const Text = styled(_Text)`
  color: ${colorTextSecondary}
  font-size: 14px
  font-weight: 600
`

const ResendCodeButton = styled(_Button)`
  padding: 0;
  margin: 0;
  border: none;
  font-weight: 600
`

const CodeExpiredText = styled(Text)`
  color: red;
`

const MAX_RESEND_CODE_SECONDS = 120

export const VerifyCodeForm = () => {
  const navigate = useNavigate()
  const [code, setCode] = useState("")

  const calcExpiresIn = (timestamp: string | null): number | null => {
    return timestamp ? Math.max(Math.floor((new Date(timestamp).getTime() - Date.now()) / 1000), 0) : null
  }

  const calcResendCodeSeconds = (codeSentAt: string | null): number => {
    if (!codeSentAt) {
      return 0;
    }
    
    const secondsSinceCodeSent = Math.floor((Date.now() - new Date(codeSentAt).getTime()) / 1000);
    
    return secondsSinceCodeSent > MAX_RESEND_CODE_SECONDS ? 0 : MAX_RESEND_CODE_SECONDS - secondsSinceCodeSent;
  }
  const optExpirationSeconds = calcExpiresIn(localStorageGetItem(LocalStorageKeys.CodeExpiresAt))
  const [resendCodeSeconds, setResendCodeSeconds] = useState(calcResendCodeSeconds(localStorageGetItem(LocalStorageKeys.CodeSentAt)))
  const [codeExpiresIn, setCodeExpiresIn] = useState(optExpirationSeconds ? optExpirationSeconds : null)


  const [verifyCodeMutation, { loading: verifyCodeLoading }] = useVerifyCodeMutation({
    onCompleted: (data) => {
      if (data?.verifyCode.__typename === 'UserNotFound') {
        message.error(data.verifyCode.error)
      } else if (data?.verifyCode?.__typename === 'UnableToVerifyCode') {
        message.error(data.verifyCode.error)
      } else if (data?.verifyCode?.__typename === 'VerifyCodeSuccess') {
        localStorageRemoveItem(LocalStorageKeys.CodeExpiresAt)
        localStorageRemoveItem(LocalStorageKeys.CodeSentAt)
        localStorageRemoveItem(LocalStorageKeys.CodeChannel)
        navigate(Routes.PASSWORD_RESET_NEW_PASSWORD)
        message.success("Code verified successfully!")
      }
    },
    onError: () => {
      message.error("Something went wrong")
    }
  })
  
  const [sendCodeByEmailMutation, { loading: sendCodeLoading }] = useSendCodeByEmailMutation({
    onError: () => {
      message.error("An unknown error occurred")
    },
    onCompleted: (data) => {
      switch (data?.sendCodeByEmail?.__typename) {
        case "UserNotFound":
          message.error(data?.sendCodeByEmail?.error)
          break
        case "UnableToSendCode":
          message.error(data?.sendCodeByEmail?.error)
          break
        case "SendCodeSuccess": {
          const codeChannel = localStorageGetItem(LocalStorageKeys.CodeChannel) as VerifyChannel
          message.success(`Code sent to your ${codeChannel === VerifyChannel.Sms ? 'phone' : 'email'}`)
          localStorageSetItem(LocalStorageKeys.CodeExpiresAt, data.sendCodeByEmail.expiresAt)
          localStorageSetItem(LocalStorageKeys.CodeSentAt, data.sendCodeByEmail.sentAt)
          setCodeExpiresIn(calcExpiresIn(data.sendCodeByEmail.expiresAt))
          setResendCodeSeconds(calcResendCodeSeconds(localStorageGetItem(LocalStorageKeys.CodeSentAt)))
          break
        }
        default:
          message.error("An unknown error occurred")
          break
      }
    },
  })

  useEffect(() => {
    if (resendCodeSeconds === 0) return

    const intervalId = setInterval(() => {
      setResendCodeSeconds(resendCodeSeconds - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [resendCodeSeconds])

  useEffect(() => {
    if (codeExpiresIn === null || codeExpiresIn === 0) return

    const intervalId = setInterval(() => {
      setCodeExpiresIn(codeExpiresIn - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [codeExpiresIn])

  const handleResendCode = async () => {
    localStorageRemoveItem(LocalStorageKeys.CodeExpiresAt)
    const codeChannel = localStorageGetItem(LocalStorageKeys.CodeChannel) ?? VerifyChannel.Sms
    localStorageSetItem(LocalStorageKeys.CodeChannel, codeChannel)

    await sendCodeByEmailMutation({
      variables: {
        email: localStorageGetItem(LocalStorageKeys.CodeEmail) ?? "",
        channel: codeChannel as VerifyChannel,
      },
    })
  }

  const handleSubmit = async ({ code }: VerifyCodeValues) => {
    await verifyCodeMutation({ variables: { code } })
  }

  const sendCodeSecondsMessage = () => {
    if (resendCodeSeconds === 0) {
      return <> </>
    } else {
      return (
        <Text>
          Resend code in{" "}
          <span style={{ color: colorPrimary }}>
            {Math.floor(resendCodeSeconds / 60)}:
            {String(resendCodeSeconds % 60).padStart(2, "0")}
          </span>
        </Text>
      )
    }
  }

  const codeExpiresInMessage = () => {
    if (codeExpiresIn === null) {
      return <></>
    } else if (codeExpiresIn === 0) {
      return <CodeExpiredText>Code Expired</CodeExpiredText>
    } else {
      return (
        <Text>
          Code expires in{" "}
          <span style={{ color: colorPrimary }}>
            {Math.floor(codeExpiresIn / 60)}:
            {String(codeExpiresIn % 60).padStart(2, "0")}
          </span>
        </Text>
      )
    }
  }

  return (
    <>
      <Card>
        <BackButton
          onClick={() => {
            navigate(-1)
          }}
          src={arrowLeft}
        />
        <Typography.Title
          style={{ textAlign: "center", fontSize: "32px", fontWeight: 700 }}
          level={3}
        >
          Verify your account
        </Typography.Title>
        <Text>{`Enter the code sent to your ${localStorageGetItem(LocalStorageKeys.CodeChannel) === VerifyChannel.Sms ? 'phone' : 'email'}`}</Text>
        <Form
          style={{ marginTop: "32px" }}
          name="register-form"
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          initialValues={{ sendVia: "SMS" }}
        >
          <Form.Item
            name="code"
            rules={[
              { required: true, message: "Please input the code" },
              { min: 6, max: 6, message: "Code must be 6 characters long" },
            ]}
          >
            <OtpInput
              shouldAutoFocus
              value={code}
              onChange={setCode}
              numInputs={6}
              renderSeparator={<span style={{ marginLeft: "8px" }}></span>}
              containerStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              inputStyle={{
                aspectRatio: "1/1",
                flexGrow: 1,
                borderRadius: "15px",
                border: "1px solid #DCE4E8",
              }}
              renderInput={(props) => <input {...props} />}
            />
          </Form.Item>
          <FormItem>
            <Button
              disabled={verifyCodeLoading}
              block
              type="primary"
              htmlType="submit"
            >
              {verifyCodeLoading ? <Spin /> : "Verify Code"}
            </Button>
          </FormItem>
          <Space style={{ paddingBottom: '10px', width: '100%' }}>
            {codeExpiresInMessage()}
          </Space>
        </Form>
        <Space 
          align="center" 
          direction="horizontal"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {sendCodeSecondsMessage()}
          <ResendCodeButton
            disabled={resendCodeSeconds > 0 && !sendCodeLoading && !verifyCodeLoading}
            block
            type="link"
            onClick={handleResendCode}
          >
            {verifyCodeLoading ? <Spin /> : "Resend Code"}
          </ResendCodeButton>
        </Space>
      </Card>
    </>
    )
}