import { Card, Progress, Space, Typography, theme } from 'antd'
import { FacebookLogo } from '../../../assets/icons'
import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const dotAnimation = keyframes`
  0% { content: ''; }
  25% { content: '.'; }
  50% { content: '..'; }
  75% { content: '...'; }
  100% { content: ''; }
`;

const LoadingText = styled(Text)`
  color: #BEBEBE;
  font-weight: 400;
  font-size: 12px;

  &::after {
    content: '';
    animation: ${dotAnimation} 2s infinite steps(1, end);
  }
`;

type ProgressCardProps = {
  initialProgress: {
    entities: number
    metrics: number
  }
}

export const ProgressCard = ({ initialProgress }: ProgressCardProps) => {
  const [runInBackground, setRunInBackground] = useState(false)

  const isDownloadingEntities = () => {
    return initialProgress.entities < 100
  }
  const isDownloadingMetrics = () => {
    return initialProgress.metrics < 100
  }
  const isFinished = () => {
    return initialProgress.entities === 100 && initialProgress.metrics === 100
  }

  const {
    token: { colorTextBase, colorBgContainer },
  } = theme.useToken()

  if (runInBackground || isFinished()) {
    return <></>
  }

  const PreparingText = () => <LoadingText>Preparing to download (~1-5 minutes)</LoadingText>

  return (
    <Card style={{ background: colorTextBase, position: 'fixed', bottom: '0', right: '0', zIndex: 10, margin: 32, color: colorBgContainer, maxWidth: 450, borderRadius: 12 }}>
      <Space direction="horizontal" align="start">
        <FacebookLogo style={{ fontSize: '40px', marginRight: '6px' }} />
        <Space direction="vertical">
          <Title style={{ color: colorBgContainer, fontWeight: 600, fontSize: 16 }}> Downloading Facebook Data</Title>
          <Text style={{ color: '#BEBEBE', fontWeight: 400, fontSize: 14 }}>This process may take up to 15 minutes.</Text>
          <Text style={{ color: colorBgContainer }}>Ads</Text>
          {initialProgress.entities === 0 ? (
            <PreparingText />
          ) : (
            <Progress
              status={isDownloadingEntities() ? 'active' : 'success'}
              percent={initialProgress.entities}
              trailColor="#37393E"
              showInfo={true}
              strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
            />
          )}
          <div style={{ width: '10px' }} /> {/* Spacing between progress indicators */}
          <Text style={{ color: colorBgContainer }}>Metrics</Text>
          {initialProgress.entities < 100 ? (
            <LoadingText>Waiting for Ads to download</LoadingText>
          ) : initialProgress.entities === 100 && initialProgress.metrics === 0 ? (
            <PreparingText />
          ) : (
            <Progress
              status={isDownloadingMetrics() ? 'active' : 'success'}
              percent={initialProgress.metrics}
              trailColor="#37393E"
              showInfo={true}
              strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
            />
          )}
          <style>{`
                  .ant-progress-text {
                    color: white !important;
                  }
                `}</style>
        </Space>
      </Space>
      <CloseOutlined onClick={() => setRunInBackground(true)} style={{ position: 'absolute', top: 16, right: 16 }} />
    </Card>
  )
}
