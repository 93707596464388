import { SorterResult } from 'antd/es/table/interface'
import { SortInput, SortDirection } from '../__generated__/graphql'

export const useMultiSort = (sortState: SortInput[], setSort: (sort: SortInput[]) => void) => {
  const sorterToOrder = (sorter: any): SortInput => ({
    column: sorter.columnKey,
    order: sorter.order === 'ascend' ? SortDirection.Asc : SortDirection.Desc,
  })

  // update using antd sorter object
  const updateOrderWithSorter = (sorter: SorterResult<object> | SorterResult<object>[]) => {
    const isMultiSort = Array.isArray(sorter)
    let newOrder
    if (isMultiSort) {
      newOrder = sorter.map(sorterToOrder)
    } else {
      newOrder = sorter.order ? [sorterToOrder(sorter)] : []
    }
    setSort(newOrder)
  }

  const getOrderForColumn = (column: string): 'ascend' | 'descend' | null => {
    const orderForColumn = sortState.find((o) => o.column === column)
    if (!orderForColumn) return null
    return orderForColumn.order === SortDirection.Asc ? 'ascend' : 'descend'
  }

  return { getOrderForColumn, updateOrderWithSorter }
}
