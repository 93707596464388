import {
  Checkbox,
  Button as _Button,
  Row,
  Form,
  Typography,
  message,
} from "antd"
import { useLoginMutation } from "../../__generated__/graphql"
import { Button } from "../StyledComponents/Button"
import { Input, InputPassword } from "../StyledComponents/Input"
import { FormItem } from "../StyledComponents/FormItem"
import { Spin } from "../StyledComponents/Spin"
import { Card } from "../StyledComponents/Card"
import { useNavigate } from "react-router-dom"
import { Routes } from "../../Routes"
import { LocalStorageKeys } from "../../common/types/localStorage"
import { client } from "../../graphql/client"
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from "../../services/storage"
import { clearPrivateStorageLogin } from "../../services/auth"
interface LoginFormValues {
  email: string
  password: string
  remember: boolean
}

export const LoginForm = () => {
  const navigate = useNavigate()
  const [loginMutation, { loading }] = useLoginMutation({
    onCompleted: async (data) => {
      if (data.login.__typename === "User") {
        if (remember) {
          localStorageSetItem(LocalStorageKeys.Email, data.login.email)
        } else {
          localStorageRemoveItem(LocalStorageKeys.Email)
        }

        clearPrivateStorageLogin()
        await client.resetStore()
        navigate(Routes.DASHBOARD)
      } else if (data.login.__typename === "InvalidCredentials") {
        message.error(data.login.error)
      } else {
        message.error("Login failed")
      }
    },
    onError: () => {
      message.error('An unknown erorr occured')
    },
  })

  const [form] = Form.useForm()

  const remember = localStorageGetItem(LocalStorageKeys.RememberMe) === "true"
  const email = remember ? localStorageGetItem(LocalStorageKeys.Email) ?? "" : ""

  const handleSubmit = async ({ email, password }: LoginFormValues) => {
    loginMutation({ variables: { email, password } })
  }

  const handleRememberChange = (e: { target: { checked: boolean } }) => {
    const value = e.target.checked
    localStorageSetItem(LocalStorageKeys.RememberMe, String(value))
    if (!value) {
      localStorageRemoveItem(LocalStorageKeys.Email)
      form.setFieldsValue({ email: "" })
    }
  }

  return (
    <>
      <Card>
        <Typography.Title
          style={{ textAlign: "center", fontSize: "32px", fontWeight: 700 }}
        >
          Login to your account
        </Typography.Title>
        <Form
          form={form}
          name="login-form"
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          initialValues={{ email, remember }}
        >
          <FormItem
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email." },
              { type: "email", message: "Please enter a valid email." },
            ]}
          >
            <Input placeholder="Email" />
          </FormItem>
          <FormItem
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password." }]}
          >
            <InputPassword placeholder="Password" />
          </FormItem>
          <FormItem>
            <Row justify={"space-between"} align={"middle"}>
              <FormItem name="remember" valuePropName="checked">
                <Checkbox onChange={handleRememberChange}>Remember me</Checkbox>
              </FormItem>
              <FormItem>
                <_Button type="link" onClick={() => navigate(Routes.PASSWORD_RESET)}>Forgot Password</_Button>
              </FormItem>
            </Row>
          </FormItem>
          <FormItem>
            <Button disabled={loading} block type="primary" htmlType="submit">
              {loading ? <Spin /> : "Log in"}
            </Button>
          </FormItem>
          <div style={{ textAlign: "center" }}>
            Don't have an account?
            <Typography.Link onClick={() => navigate(Routes.REGISTER)}> Register Here</Typography.Link>
          </div>
        </Form>
      </Card>
    </>
  )
}
