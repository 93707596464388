import { Form, Typography, message, Radio as _Radio, Row, RadioChangeEvent } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spin, FormItem, Card, Button, Input, BackButton } from '../StyledComponents'
import styled from 'styled-components'
import { colorPrimary, colorTextSecondary } from '../../theme/colors'
import { Routes } from '../../Routes'
import { VerifyChannel, useSendCodeByEmailMutation } from '../../__generated__/graphql'
import { LocalStorageKeys, LocalStorageTypes } from '../../common/types/localStorage'
import arrowLeft from '../../assets/arrow_left.svg'
import { localStorageSetItem } from '../../services/storage'
import { useEffect } from 'react'
import { useLocalStorage } from '../../hooks/useLocalStorage'

interface SendCodeFormValues {
  channel: VerifyChannel
}

const Radio = styled(_Radio)`
  .ant-radio-inner::after {
    background-color: ${colorPrimary};
  }
  .ant-radio-inner {
    border-width: 2px;
    border-color: ${colorPrimary};
  }
  .ant-radio-checked .ant-radio-inner {
    background-color: white;
    border-width: 2px;
  }
`

const Text = styled(Typography.Text)`
  text-align: center;
  color: ${colorTextSecondary};
  font-size: 14px;
  font-weight: 600;
`

export const SendCodeForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { email } = location.state || {}
  const [codeChannel, setCodeChannel] = useLocalStorage(LocalStorageKeys.CodeChannel, LocalStorageTypes.String, VerifyChannel.Sms)

  useEffect(() => {
    if (!email) {
      navigate('/')
    }
  }, [email, navigate])

  const [form] = Form.useForm()
  const [sendCodeByEmailMutation, { loading }] = useSendCodeByEmailMutation({
    onError: (e) => {
      console.log(e.graphQLErrors)
      message.error(`An unknown error occurred ${e}`)
    },
    onCompleted: async (data) => {
      switch (data?.sendCodeByEmail?.__typename) {
        case 'UserNotFound':
          message.error(data?.sendCodeByEmail?.error)
          break
        case 'UnableToSendCode':
          message.error(data?.sendCodeByEmail?.error)
          break
        case 'SendCodeSuccess':
          localStorageSetItem(LocalStorageKeys.CodeExpiresAt, data.sendCodeByEmail.expiresAt)
          localStorageSetItem(LocalStorageKeys.CodeSentAt, data.sendCodeByEmail.sentAt)
          navigate(Routes.PASSWORD_RESET_VERIFY_CODE)
          break
        default:
          message.error('An unknown error occurred')
          break
      }
    },
  })

  const handleSubmit = async () => {
    try {
      const values: SendCodeFormValues = await form.validateFields(['channel'])
      console.log(values)
      localStorageSetItem(LocalStorageKeys.CodeEmail, email)

      await sendCodeByEmailMutation({
        variables: {
          email: email,
          channel: values.channel,
        },
      })
    } catch (error) {
      message.error(`An unknown error occurred ${error}`)
    }
  }

  const handleChannelChange = (e: RadioChangeEvent) => {
    const newChannel = e.target.value
    setCodeChannel(newChannel)
  }

  return (
    <>
      <Card>
        <BackButton
          onClick={() => {
            navigate(-1)
          }}
          src={arrowLeft}
        />
        <div style={{ textAlign: 'left', marginTop: '44px' }}>
          <Typography.Title style={{ fontSize: '32px', fontWeight: 700 }} level={3}>
            Reset Password
          </Typography.Title>
          <Text>Choose how you want to receive the code to change your password</Text>
        </div>
        <Form
          style={{ marginTop: '32px' }}
          form={form}
          name="send-code-form"
          layout="vertical"
          requiredMark={false}
          initialValues={{ channel: codeChannel }}
        >
          <Form.Item name="channel" rules={[{ required: true, message: 'Please select a medium' }]}>
            <Radio.Group style={{ width: '100%' }} onChange={handleChannelChange} value={codeChannel}>
              <Row justify={'space-around'}>
                <Radio value={VerifyChannel.Sms}>By Text</Radio>
                <Radio value={VerifyChannel.Email}>By Email</Radio>
              </Row>
            </Radio.Group>
          </Form.Item>
          <FormItem>
            <Button disabled={loading} block type="primary" onClick={handleSubmit}>
              {loading ? <Spin /> : 'Send Code'}
            </Button>
          </FormItem>
        </Form>
      </Card>
    </>
  )
}
