import { Typography } from 'antd'
const { Text } = Typography

interface CopyableTextProps {
    text: string
    backgroundColor: string
    showButtonInUpperRight?: boolean
}

export const CopyableText = ({ text, backgroundColor, showButtonInUpperRight = false }: CopyableTextProps) => {
    const isMultiLine = text.includes('\n')
    const textStyle = {
        display: 'flex',
        whiteSpace: 'pre-wrap',
        lineHeight: '1.5',
        justifyContent: isMultiLine && showButtonInUpperRight ? 'flex-start' : 'space-between',
        background: backgroundColor,
        padding: 16,
        borderRadius: 8,
        fontWeight: 500,
        fontSize: 16,
        position: 'relative' as const
    }
    return (
        <div style={{ position: 'relative' }}>
            <Text style={{ ...textStyle, paddingRight: isMultiLine && showButtonInUpperRight ? '48px' : '16px', whiteSpace: 'pre-wrap' }} copyable={!showButtonInUpperRight}>
                {text}
            </Text>
            {isMultiLine && showButtonInUpperRight && (
                <span style={{ position: 'absolute', top: 16, right: 16 }}>
                    <Text copyable={{text}} />
                </span>
            )}
        </div>
    )
}