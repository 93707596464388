import { Row as _Row } from 'antd'
import { SendCodeForm } from '../../components/SendCodeForm'
import { PasswordResetLayout } from '../../layouts/PasswordResetLayout'

export const SendCode = () => {
  return (
    <PasswordResetLayout>
      <SendCodeForm />
    </PasswordResetLayout>
  )
}
