export enum Routes {
  LOGIN = '/login',
  DASHBOARD = '/',
  ATTRIBUTION = '/attribution',
  REGISTER = '/register',
  PASSWORD_RESET = '/password-reset',
  PASSWORD_RESET_VERIFY_ACCOUNT = '/password-reset/verify-account',
  PASSWORD_RESET_SEND_CODE = '/password-reset/send-code',
  PASSWORD_RESET_VERIFY_CODE = '/password-reset/verify-code',
  PASSWORD_RESET_NEW_PASSWORD = '/password-reset/new-password',
  CREATE_ORGANIZATION = '/create-organization',
  CREATE_STORE = '/create-store',
  CONNECTIONS = '/connections',
  PROFILE = '/profile',
  QUICK_START = '/quick-start',
  SETUP_UTM = '/setup-utm',
  SETTINGS = '/settings',
  //SUBSCRIPTION = '/subscription',
  PROFILE_SETTINGS = '/general-settings/profile',
  SUPPORT_SETTINGS = '/general-settings/support',
  //SUBSCRIPTION_SETTINGS = '/general-settings/subscription',
  SETUP_TRACKING = '/setup-tracking',
  LEGAL = '/legal',
  REFUND_POLICY = '/legal/refund-policy',
  TERMS_OF_SERVICE = '/legal/terms-of-service',
  PRIVACY_POLICY = '/legal/privacy-policy',
}
