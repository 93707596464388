import { Form, Typography, message, Radio as _Radio } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FormItem, Card, Button, Input, BackButton } from '../StyledComponents'
import styled from 'styled-components'
import { colorTextSecondary } from '../../theme/colors'
import { Routes } from '../../Routes'
import { LocalStorageKeys } from '../../common/types/localStorage'
import arrowLeft from '../../assets/arrow_left.svg'
import { localStorageSetItem } from '../../services/storage'

interface VerifyAccountFormValues {
  email: string
}

const Text = styled(Typography.Text)`
  text-align: center;
  color: ${colorTextSecondary};
  font-size: 14px;
  font-weight: 600;
`

export const VerifyAccountForm = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const handleSubmit = async () => {
    try {
      const values: VerifyAccountFormValues = await form.validateFields(['email'])
      navigate(Routes.PASSWORD_RESET_SEND_CODE, { state: { email: values.email } })
    } catch (error) {
      message.error(`An unknown error occurred ${error}`)
    }
  }

  return (
    <>
      <Card style={{ width: '100%' }}>
        <BackButton
          onClick={() => {
            navigate(-1)
          }}
          src={arrowLeft}
        />
        <div style={{ textAlign: 'left', marginTop: '44px' }}>
          <Typography.Title style={{ fontSize: '32px', fontWeight: 700 }} level={3}>
            Reset Password
          </Typography.Title>
          <Text>Start by entering your email attached to your account</Text>
        </div>
        <Form
          style={{ marginTop: '32px' }}
          form={form}
          name="verify-account-form"
          layout="vertical"
          requiredMark={false}
        >
          <FormItem
            name="email"
            rules={[
              { required: true, message: 'Please input your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder={'Email'} />
          </FormItem>
          <FormItem>
            <Button block type="primary" onClick={handleSubmit}>
              {'Next'}
            </Button>
          </FormItem>
        </Form>
      </Card>
    </>
  )
}
