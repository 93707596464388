import dayjs from '../../services/dayjs'
import { noteOnce } from 'rc-util/lib/warning'

const parseLocale = (locale: string) => {
  const mapLocale = localeMap[locale]
  return mapLocale || locale.split('_')[0]
}

const parseNoMatchNotice = () => {
  /* istanbul ignore next */
  noteOnce(false, 'Not match any format. Please help to fire a issue about this.')
}

type IlocaleMapObject = Record<string, string>
const localeMap: IlocaleMapObject = {
  // ar_EG:
  // az_AZ:
  // bg_BG:
  bn_BD: 'bn-bd',
  by_BY: 'be',
  // ca_ES:
  // cs_CZ:
  // da_DK:
  // de_DE:
  // el_GR:
  en_GB: 'en-gb',
  en_US: 'en',
  // es_ES:
  // et_EE:
  // fa_IR:
  // fi_FI:
  fr_BE: 'fr', // todo: dayjs has no fr_BE locale, use fr at present
  fr_CA: 'fr-ca',
  // fr_FR:
  // ga_IE:
  // gl_ES:
  // he_IL:
  // hi_IN:
  // hr_HR:
  // hu_HU:
  hy_AM: 'hy-am',
  // id_ID:
  // is_IS:
  // it_IT:
  // ja_JP:
  // ka_GE:
  // kk_KZ:
  // km_KH:
  kmr_IQ: 'ku',
  // kn_IN:
  // ko_KR:
  // ku_IQ: // previous ku in antd
  // lt_LT:
  // lv_LV:
  // mk_MK:
  // ml_IN:
  // mn_MN:
  // ms_MY:
  // nb_NO:
  // ne_NP:
  nl_BE: 'nl-be',
  // nl_NL:
  // pl_PL:
  pt_BR: 'pt-br',
  // pt_PT:
  // ro_RO:
  // ru_RU:
  // sk_SK:
  // sl_SI:
  // sr_RS:
  // sv_SE:
  // ta_IN:
  // th_TH:
  // tr_TR:
  // uk_UA:
  // ur_PK:
  // vi_VN:
  zh_CN: 'zh-cn',
  zh_HK: 'zh-hk',
  zh_TW: 'zh-tw',
}

const generateConfig: any = () => ({
  // get
  getNow: () => dayjs(),
  getFixedDate: (string: string) => dayjs(string, ['YYYY-M-DD', 'YYYY-MM-DD']),
  getEndDate: (date: any) => date.endOf('month'),
  getWeekDay: (date: any) => {
    const clone = date.locale('en')
    return clone.weekday() + clone.localeData().firstDayOfWeek()
  },
  getYear: (date: any) => date.year(),
  getMonth: (date: any) => date.month(),
  getDate: (date: any) => date.date(),
  getHour: (date: any) => date.hour(),
  getMinute: (date: any) => date.minute(),
  getSecond: (date: any) => date.second(),
  getMillisecond: (date: any) => date.millisecond(),

  // set
  addYear: (date: any, diff: any) => date.add(diff, 'year'),
  addMonth: (date: any, diff: any) => date.add(diff, 'month'),
  addDate: (date: any, diff: any) => date.add(diff, 'day'),
  setYear: (date: any, year: any) => date.year(year),
  setMonth: (date: any, month: any) => date.month(month),
  setDate: (date: any, num: any) => date.date(num),
  setHour: (date: any, hour: any) => date.hour(hour),
  setMinute: (date: any, minute: any) => date.minute(minute),
  setSecond: (date: any, second: any) => date.second(second),
  setMillisecond: (date: any, milliseconds: any) => date.millisecond(milliseconds),

  // Compare
  isAfter: (date1: any, date2: any) => date1.isAfter(date2),
  isValidate: (date: any) => date.isValid(),

  locale: {
    getWeekFirstDay: (locale: any) => dayjs().locale(parseLocale(locale)).localeData().firstDayOfWeek(),
    getWeekFirstDate: (locale: any, date: any) => date.locale(parseLocale(locale)).weekday(0),
    getWeek: (locale: any, date: any) => date.locale(parseLocale(locale)).week(),
    getShortWeekDays: (locale: any) => dayjs().locale(parseLocale(locale)).localeData().weekdaysMin(),
    getShortMonths: (locale: any) => dayjs().locale(parseLocale(locale)).localeData().monthsShort(),
    format: (locale: any, date: any, format: any) => date.locale(parseLocale(locale)).format(format),
    parse: (locale: any, text: any, formats: any) => {
      const localeStr = parseLocale(locale)
      for (let i = 0; i < formats.length; i += 1) {
        const format = formats[i]
        const formatText = text
        if (format.includes('wo') || format.includes('Wo')) {
          // parse Wo
          const year = formatText.split('-')[0]
          const weekStr = formatText.split('-')[1]
          const firstWeek = dayjs(year, 'YYYY').startOf('year').locale(localeStr)
          for (let j = 0; j <= 52; j += 1) {
            const nextWeek = firstWeek.add(j, 'week')
            if (nextWeek.format('Wo') === weekStr) {
              return nextWeek
            }
          }
          parseNoMatchNotice()
          return null
        }
        const date = dayjs(formatText, format, true).locale(localeStr)
        if (date.isValid()) {
          return date
        }
      }

      if (text) {
        parseNoMatchNotice()
      }
      return null
    },
  },
})

export default generateConfig
