export const PromotionIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.584 2.8125C20.539 2.81255 20.494 2.81657 20.4497 2.8245L4.00898 5.8125C3.42559 5.83271 2.87282 6.07856 2.4671 6.49826C2.06139 6.91797 1.83441 7.47876 1.83398 8.0625V12.9375C1.8344 13.5229 2.06269 14.0852 2.4705 14.5051C2.87831 14.9251 3.4336 15.1699 4.01873 15.1875L4.08398 15.198V20.4375C4.08398 20.6364 4.163 20.8272 4.30365 20.9678C4.44431 21.1085 4.63507 21.1875 4.83398 21.1875H6.33398C6.93054 21.1869 7.50249 20.9497 7.92432 20.5278C8.34614 20.106 8.58339 19.5341 8.58398 18.9375V15.9127L20.4662 17.8035C20.5052 17.8093 20.5446 17.8123 20.584 17.8125C21.1805 17.8119 21.7525 17.5747 22.1743 17.1528C22.5961 16.731 22.8334 16.1591 22.834 15.5625V5.0625C22.8334 4.46595 22.5961 3.894 22.1743 3.47217C21.7525 3.05034 21.1805 2.8131 20.584 2.8125ZM7.08398 18.9375C7.08398 19.1364 7.00497 19.3272 6.86431 19.4678C6.72366 19.6085 6.5329 19.6875 6.33398 19.6875H5.58398V15.4354L7.08398 15.6742V18.9375ZM17.959 15.8854L4.20173 13.6965C4.16275 13.6907 4.1234 13.6877 4.08398 13.6875C3.88507 13.6875 3.69431 13.6085 3.55365 13.4678C3.413 13.3272 3.33398 13.1364 3.33398 12.9375V8.0625C3.33398 7.86359 3.413 7.67282 3.55365 7.53217C3.69431 7.39152 3.88507 7.3125 4.08398 7.3125C4.129 7.31245 4.17392 7.30843 4.21823 7.3005L17.959 4.80225V15.8854ZM21.334 15.5625C21.3344 15.7532 21.2622 15.9369 21.132 16.0762C21.0018 16.2155 20.8234 16.3 20.6331 16.3125L19.459 16.125V4.52963L20.6402 4.31475C20.8289 4.32894 21.0051 4.41387 21.1338 4.55253C21.2624 4.6912 21.334 4.87335 21.334 5.0625V15.5625Z"
      />
      <path
        d="M5.20898 11.0625H7.45898C7.6579 11.0625 7.84866 10.9835 7.98931 10.8428C8.12997 10.7022 8.20898 10.5114 8.20898 10.3125C8.20898 10.1136 8.12997 9.92282 7.98931 9.78217C7.84866 9.64152 7.6579 9.5625 7.45898 9.5625H5.20898C5.01007 9.5625 4.81931 9.64152 4.67865 9.78217C4.538 9.92282 4.45898 10.1136 4.45898 10.3125C4.45898 10.5114 4.538 10.7022 4.67865 10.8428C4.81931 10.9835 5.01007 11.0625 5.20898 11.0625Z"
      />
    </svg>
  )
}
