import { CheckCircleFilled } from '@ant-design/icons'
import { theme, Card, Row, Col, Space, Button as _Button, Typography } from 'antd'
import styled from 'styled-components'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { calcBreakpoint } from '../../../common/utils/calcBreakpoints'
import { LocalStorageKeys, LocalStorageTypes } from '../../../common/types/localStorage'

const { Text } = Typography
const TitleText = styled(Text)`
  font-weight: 600;
  font-size: 20px;
`
const Button = styled(_Button)`
  font-weight: 700;
  background-color: #1877F2;
  color: white;
  overflow: clip;
  white-space: wrap;
  height: auto !important;
  padding: 10px 24px !important;

  &:hover {
    color: white !important;
  }
`
const ButtonText = styled(Text)`
  font-weight: 700;
  font-size: 16px;
`

const PlatformCol = styled(Col)<{ breakpoint?: string }>`
  @media (min-width: ${(props) => props.breakpoint}) {
    display: block;
  }
  display: flex;
  justify-content: center;
`
interface AdPlatformCardProps {
  title: string
  icon: string
  isConnected: boolean
  loading: boolean
  connectionLink?: string
  onDisconnect: () => void
}

export const AdPlatformCard = ({ title, icon, isConnected, connectionLink, loading, onDisconnect }: AdPlatformCardProps) => {
  const [siderCollapse] = useLocalStorage(LocalStorageKeys.SiderCollapse, LocalStorageTypes.Boolean, false)
  const [isMobile] = useLocalStorage(LocalStorageKeys.IsMobile, LocalStorageTypes.Boolean, false)
  const {
    token: { colorBgLayout, colorBgContainer, colorSuccess, colorTextTertiary, colorPrimary },
  } = theme.useToken()
  return (
    <Card style={{ backgroundColor: colorBgLayout, borderRadius: 16 }}>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
          <Row gutter={[8, 8]} align={'middle'} justify={'space-between'}>
            <PlatformCol breakpoint={calcBreakpoint('md', isMobile, siderCollapse)} span={24} md={24}>
              <TitleText style={{ textAlign: 'center' }}>{title}</TitleText>
            </PlatformCol>
          </Row>
        </Col>
        <Col style={{ margin: 'auto' }} span={24} md={12}>
          <Row gutter={16} align={'middle'} justify={{ md: 'end', xs: 'space-around' }}>
            {isConnected && (
              <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 4 }} span={12} xs={24} sm={12} md={24} lg={10}>
                <Space direction="horizontal">
                  <CheckCircleFilled color={colorSuccess} style={{ fontSize: '18px', color: colorSuccess }} />
                  <ButtonText style={{ color: colorTextTertiary, wordBreak: 'initial' }}>Connected</ButtonText>
                </Space>
              </Col>
            )}
            <Col span={isConnected ? 12 : 24} xs={24} sm={isConnected ? 12 : 24} md={24} lg={14}>
              <Button
                loading={loading}
                onClick={() => {
                  !isConnected && window.open(connectionLink, '_blank')
                  isConnected && onDisconnect()
                }}
                type="primary"
                size="large"
                block
              >
                {isConnected ? 'Disconnect' : 'Login with Facebook'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
