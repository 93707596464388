import styled from "styled-components";

export const Background = styled.div<{src:string, offset?:string, scale?:string}>`
  background-image: url(${(props)=>props.src});
  min-height: 100%;
  background-size: cover;
  position: relative;
  background-position: center ${(props)=> props.offset ? props.offset : '0'};
  background-size: ${(props)=> props.scale ? props.scale : 'cover'};
  background-repeat: no-repeat;
`;