import styled from 'styled-components'
import { PhoneInput as _PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

export const PhoneInput = styled(_PhoneInput)<{ border: string }>`
  height: 62px !important;
  border: solid 1px ${(props) => props.border};
  border-radius: 15px;
  padding: 0;
  input {
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    outline: none;
    font-size: 16px !important;
    border-radius: 15px !important;
  }
  .react-international-phone-country-selector {
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-right: solid 1px ${(props) => props.border} !important;
  }
  .react-international-phone-dial-code-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px !important;
    border: none;
    line-height: normal;
    padding-right: 0px;
  }
  .react-international-phone-country-selector-button {
    border-radius: 15px 0 0 15px !important;
    background: none !important;
    border: none !important;
    padding: 0 10px !important;
    height: 100%;
    margin: 0 !important;
  }
  .react-international-phone-country-selector-dropdown {
    border-radius: 8px !important;
    margin-top: 20px !important;
  }
`
