export const BrowserWindowIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.9784 4H4.35369C3.4231 4 2.66602 4.75725 2.66602 5.68779V18.9952C2.66602 19.9258 3.4231 20.6829 4.35364 20.6829H20.9784C21.9089 20.6829 22.666 19.9257 22.666 18.9952V5.68779C22.6661 4.75725 21.909 4 20.9784 4ZM4.35364 5.43006H20.9784C21.1204 5.43006 21.2364 5.54568 21.2364 5.68779V9.12417H4.09567V5.68779C4.09567 5.54568 4.21165 5.43006 4.35364 5.43006ZM20.9784 19.2529H4.35369C4.21165 19.2529 4.09567 19.1372 4.09567 18.9952V10.5542H21.2364V18.9952C21.2364 19.1372 21.1204 19.2529 20.9784 19.2529Z"
      />
      <path
        d="M6.19036 8.1691C6.71557 8.1691 7.1418 7.74324 7.1418 7.21832C7.1418 6.6934 6.71557 6.26758 6.19036 6.26758C5.66573 6.26758 5.24023 6.69344 5.24023 7.21832C5.24023 7.74324 5.66573 8.1691 6.19036 8.1691Z"
      />
      <path
        d="M8.73468 8.1691C9.25911 8.1691 9.6846 7.74324 9.6846 7.21832C9.6846 6.6934 9.25915 6.26758 8.73468 6.26758C8.20947 6.26758 7.7832 6.69344 7.7832 7.21832C7.78324 7.74324 8.20947 8.1691 8.73468 8.1691Z"
      />
      <path
        d="M11.2781 8.1691C11.8033 8.1691 12.2295 7.74324 12.2295 7.21832C12.2295 6.6934 11.8033 6.26758 11.2781 6.26758C10.7536 6.26758 10.3281 6.69344 10.3281 7.21832C10.3282 7.74324 10.7536 8.1691 11.2781 8.1691Z"
      />
    </svg>
  )
}
