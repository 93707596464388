import { Tabs as _Tabs } from 'antd'
import styled from 'styled-components'

interface TabsProps {
  colorprimary?: string;
  backgroundcolorsecondary?: string;
  backgroundactive?: string;
  minwidth?: string;
}

export const Tabs = styled(_Tabs)<TabsProps>`
  padding: 0px !important;
  .ant-tabs-nav {
    z-index: 4;
    margin-bottom: 0 !important;
  }
  .ant-tag-close-icon {
    margin-right: 0 !important;
  }
  .ant-tabs-nav-more {
    display: none;
  }
  .ant-tabs-nav-list {
    overflow: auto;
  }
  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }
  .ant-tabs-nav::before {
    display: none;
  }
  & > .ant-tabs-nav > .ant-tabs-nav-operations {
    display: none;
  }
  .ant-tabs-nav-list {
    align-items: flex-end;
  }
  .ant-tabs-tab {
    display: inherit;
    overflow: hidden;
    position: relative !important;
    box-shadow: inset rgba(217, 217, 217, 50%) 0px -6px 7px -1px, 0px 2px 8px 0px #00000045;
    margin: 8px 8px 0 8px !important;
    flex: 1;
    text-align: center;
    justify-content: start;
    background-color: ${(props) => props.backgroundcolorsecondary} !important;
    border: none !important;
    transition: all 0s !important;
    min-width: ${(props) => props.minwidth} !important;
    border-radius: 12px 12px 0 0 !important;
    & svg path {
      fill: black;
    }
    background: #FAFAFA !important;
    padding: 8px 8px 8px 12px !important;
  }
  .ant-tabs-tab:hover {
    color: ${(props) => props.colorprimary} !important;
    & svg path {
      fill: ${(props) => props.colorprimary};
    }
  }
  .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    background: inherit;
    transition: all 0s !important;
  }
  .ant-tabs-tab-active {
    box-shadow: 0px 2px 8px 0px #00000045; !important;
    background-color: ${(props) => props.backgroundactive} !important;
    height: 44px !important;
    .ant-tabs-tab-btn {
      color: ${(props) => props.colorprimary} !important;
      font-weight: 700;
      font-size: 18px;
    }
    & svg path {
      fill: ${(props) => props.colorprimary};
    }
  }
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    height: 40px !important;
  }
  @media (max-width: 768px) {
    .ant-tabs-tab {
      justify-content: center !important;
    }
  }
  .ant-tabs-content-holder {
    box-shadow: 0px 2px 8px 0px #00000045;
  }
`
