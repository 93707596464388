import { Card, Layout } from 'antd';

import { ProfileAction } from '../../components/HeaderActions/Profile';
import { ProfileSettingsForm } from '../../components/ProfileSettingsForm';
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout';


export const ProfileSettings = () => {
  return (
    <ResponsiveSiderLayout
      title="My Profile"
      primaryPageKey="profile-settings"
      actions={<ProfileAction />}
      mobileActions={[<ProfileAction key={4} />]}
    >
      <Card style={{ margin: '24px' }} >
        <Layout style={{ backgroundColor: 'white' }} hasSider>
          <ProfileSettingsForm />
        </Layout>
      </Card>
    </ResponsiveSiderLayout>
  );
};


