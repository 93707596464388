import { Form, Typography, message, theme } from 'antd'
import { Button } from '../StyledComponents/Button'
import { Input } from '../StyledComponents/Input'
import { FormItem } from '../StyledComponents/FormItem'
import { Card } from '../StyledComponents/Card'
import { getData } from 'country-list'
import { Select } from '../StyledComponents/Select'
import { useCreateOrganizationMutation } from '../../__generated__/graphql'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../Routes'
import { Spin } from '../StyledComponents'
import { sortCountries } from '../../utils/sortCountries'

interface CreateOrganizationFormValues {
  name: string
  country: string
  adSpend: string
  referralSource: string
}

const monthlyAdSpendOptions = [
  { label: '$1k and less', value: '$1k and less' },
  { label: '$1k-$2k', value: '$1k-$2k' },
  { label: '$2k-$5k', value: '$2k-$5k' },
  { label: '$5k-$10k', value: '$5k-$10k' },
  { label: '$10k and above', value: '$10k and above' },
]

const referralSourceOptions = [
  { label: 'Friend', value: 'friend' },
  { label: 'Colleague', value: 'colleague' },
  { label: 'Advertisment', value: 'advertisment' },
  { label: 'Other', value: 'other' },
]

const { Title } = Typography

export const CreateOrganizationForm = () => {
  const navigate = useNavigate()
  const countries = getData().sort(sortCountries)
  const [createOrganizationMutation, { loading }] = useCreateOrganizationMutation({
    onCompleted: (data) => {
      switch (data?.createOrganization?.__typename) {
        case 'Organization':
          navigate(Routes.DASHBOARD, { state: { organizationId: data.createOrganization.id } })
          break
        case 'UnableToCreateOrganization':
          message.error(data.createOrganization?.error)
          break
        default:
          message.error('Create organization failed')
      }
    },
    onError: () => {
      message.error('An unknown erorr occured')
    },
  })
  const [form] = Form.useForm()
  const handleSubmit = async ({ name, adSpend, country, referralSource }: CreateOrganizationFormValues) => {
    try {
      await createOrganizationMutation({
        variables: {
          input: {
            adSpend,
            country,
            name,
            referralSource,
          },
        },
      })
    } catch (error) {
      message.error('An unknown erorr occured')
    }
  }
  const {
    token: { colorTextTertiary },
  } = theme.useToken()
  return (
    <>
      <Card style={{ textAlign: 'center' }}>
        <Title style={{ textAlign: 'center', fontSize: '32px', fontWeight: 700 }}>Setup Your Organization</Title>
        <Title style={{ fontWeight: 600, fontSize: 16, color: colorTextTertiary }}>Tell us about your company.</Title>
        <Form form={form} name="create-org-form" layout="vertical" requiredMark={false} onFinish={handleSubmit}>
          <FormItem label="Company Name" name="name" rules={[{ required: true, message: 'Please enter the name of your company.' }]}>
            <Input placeholder="Company Name" />
          </FormItem>
          <FormItem label="Country" name="country" rules={[{ required: true, message: 'Please input your country.' }]}>
            <Select filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} showSearch size="large" placeholder="Select a country" allowClear>
              {countries.map((c) => (
                <Select.Option key={c.code} value={c.code}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="Monthly Ad Spend" name="adSpend" rules={[{ required: true, message: 'Please input monthly ad spend' }]}>
            <Select size="large" placeholder="Select your monthly ad spend" allowClear>
              {monthlyAdSpendOptions.map((i) => (
                <Select.Option key={i.value} value={i.value}>
                  {i.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label="How did you hear about us?" name="referralSource" rules={[{ required: true, message: 'How did you hear about us?' }]}>
            <Select size="large" placeholder="How did you hear about us?" allowClear>
              {referralSourceOptions.map((i) => (
                <Select.Option key={i.value} value={i.value}>
                  {i.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem>
            <Button disabled={loading} block type="primary" htmlType="submit">
              {loading ? <Spin /> : 'Next'}
            </Button>
          </FormItem>
        </Form>
      </Card>
    </>
  )
}
