import { Tag as _Tag } from 'antd'
import Icon, { SearchOutlined, CloseCircleFilled } from '@ant-design/icons'
import { useState, useCallback, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { SearchBarTag, SearchSelect } from './SearchSelect'
import React from 'react'
import { AttributionTableTabs } from '../../machines/dashboardMachine/dashboardMachine'
import { FolderIcon } from '../../assets/dashboard_assets/ph_folder'
import { BrowserWindowIcon } from '../../assets/dashboard_assets/browser-window'
import { PromotionIcon } from '../../assets/dashboard_assets/promotion'

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
`

const SearchBarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
`

const SearchBarInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 4px 8px;
  align-items: center;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
`

const Tag = styled(_Tag)`
  border: 1px solid #d9d9d9;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  margin: 2px;
  color: black !important;
`

const CloseButton = styled(CloseCircleFilled)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  
  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
`

export interface SearchTerms {
  key: AttributionTableTabs
  value: string
}

interface SearchBarProps {
  currentTab: AttributionTableTabs
  onSearch: (searchQuery: SearchTerms[]) => void
  searchTerms: SearchTerms[]
}

export interface SearchQuery {
  campaigns: string[]
  adsets: string[]
  ads: string[]
}

interface TagRenderProps {
  key: AttributionTableTabs
  label: React.ReactNode
  value: string
}

export interface SearchOption {
  key: AttributionTableTabs
  label: React.ReactNode
}

const LabelContainer = styled.span`
  display: flex;
  align-items: center;
  & svg path {
    fill: black;
  }
`

const LabelText = styled.span`
  font-weight: bold;
  margin-left: 8px;
`

const ContainsText = styled.span`
  color: #8c8c8c;
  margin-left: 4px;
`

const Label = ({ Logo, type }: { Logo: () => JSX.Element; type: AttributionTableTabs }) => (
  <LabelContainer>
    <Icon component={() => <Logo />} />
    <LabelText>{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()} name</LabelText>
    <ContainsText>contains</ContainsText>
  </LabelContainer>
)

export const options: SearchOption[] = [
  {
    key: AttributionTableTabs.CAMPAIGNS,
    label: <Label Logo={FolderIcon} type={AttributionTableTabs.CAMPAIGNS} />,
  },
  {
    key: AttributionTableTabs.ADSETS,
    label: <Label Logo={BrowserWindowIcon} type={AttributionTableTabs.ADSETS} />,
  },
  {
    key: AttributionTableTabs.ADS,
    label: <Label Logo={PromotionIcon} type={AttributionTableTabs.ADS} />,
  },
]

const optionLabelsByKey = options.reduce((acc, option) => {
  acc[option.key] = option.label
  return acc
}, {} as Record<AttributionTableTabs, React.ReactNode>)


export const SearchBar = ({ currentTab, onSearch, searchTerms }: SearchBarProps) => {
  const [tags, setTags] = useState<TagRenderProps[]>(() => {
    return searchTerms.map(tag => {
      const keyParts = tag.key.split('-');
      const tabType = keyParts[0] as AttributionTableTabs;
      const matchingOption = optionLabelsByKey[tabType];
      
      return {
        key: tag.key,
        label: matchingOption,
        value: tag.value
      };
    });
  });

  const [focus, setFocus] = useState(false)
  const [searchSelectKey, setSearchSelectKey] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleTagClose = useCallback((tagToRemove: TagRenderProps) => {
    setTags((prevTags) => prevTags.filter((tag) => tag.key !== tagToRemove.key))
    setSearchSelectKey((prevKey) => prevKey + 1)
  }, [])

  useEffect(() => {
    onSearch(tags.map(tag => ({ key: tag.key, value: tag.value })))
  }, [tags])
  
  const tagRender = useCallback(({ key, label, value }: TagRenderProps) => {
    return (
      <Tag
        key={key}
        color="blue"
        closable
        onClose={() => handleTagClose({ key, label, value })}
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {label}<span style={{ marginLeft: '4px' }}>{value}</span>
      </Tag>
    )
  }, [handleTagClose])

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!focus) {
      setFocus(true)
    }
  }

  const handleSelect = useCallback((option: SearchBarTag) => {
    setTags((prevTags) => [...prevTags, { ...option, key: `${option.key}-${option.value}-${Date.now()}` as AttributionTableTabs }])
    setSearchSelectKey((prevKey) => prevKey + 1)
  }, [])

  const handleBackspace = useCallback(() => {
    setTags((prevTags) => {
      if (prevTags.length > 0) {
        setSearchSelectKey((prevKey) => prevKey + 1)
        return prevTags.slice(0, -1)
      }
      return prevTags
    })
  }, [])

  const handleClearAll = useCallback(() => {
    setTags([])
    setSearchSelectKey((prevKey) => prevKey + 1)
    setFocus(false)
  }, [])

  return (
    <SearchBarContainer ref={containerRef} onMouseDown={handleMouseDown}>
      <SearchBarContent>
        <SearchOutlined />
        <SearchBarInput>
          {tags.map((tag) => (
            <React.Fragment key={tag.key}>
              {tagRender(tag)}
            </React.Fragment>
          ))}
          <SearchSelect
            key={searchSelectKey}
            options={options}
            focus={focus}
            currentTab={currentTab}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onSelect={handleSelect}
            onBackspace={handleBackspace}
          />
        </SearchBarInput>
      </SearchBarContent>
      <CloseButton onClick={handleClearAll} style={{ visibility: tags.length > 0 ? 'visible' : 'hidden' }} />
    </SearchBarContainer>
  )
}
