import { getTimeZones, TimeZone } from '@vvo/tzdb'
import dayjs from '../../services/dayjs'
import Fuse from 'fuse.js'

type CustomTimeZone = TimeZone & { offsetInHours: string }

type FilterTimezonesFunction = (search: string) => CustomTimeZone[]

export const filterTimezones = (limit = 50): FilterTimezonesFunction => {
  const timezones = getTimeZones({ includeUtc: true }).map((tz) => ({
    ...tz,
    offsetInHours: dayjs().tz(tz.name).format('Z'),
  }))
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (search: string): CustomTimeZone[] => {
    let results = timezones

    if (search === '') {
      const userTimeZoneIndex = timezones.findIndex((tz) => tz.name === userTimeZone)

      if (userTimeZoneIndex !== -1) {
        results = [timezones[userTimeZoneIndex], ...timezones.slice(0, userTimeZoneIndex), ...timezones.slice(userTimeZoneIndex + 1)]
      }
      return results.slice(0, limit)
    }

    const fuseOptions = {
      // isCaseSensitive: false,
      // includeScore: true,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ['name', 'abbreviation', 'mainCities', 'alternativeName', 'offsetInHours', 'countryName', 'countryCode'],
    }

    const fuse = new Fuse(timezones, fuseOptions)

    results = fuse
      .search(search)
      .slice(0, limit)
      .map((r) => r.item)
    return results
  }
}
