import { AttributionTableTabs, DashboardMachineState, RowKey } from '../machines/dashboardMachine/dashboardMachine'

// TODO: Move this to the backend
export const aggregateSelectedItems = (data: any[], selectedRowKeys: RowKey[]) => {
  return data
    .filter((row) => selectedRowKeys.includes(row.id))
    .reduce(
      (acc, item) => ({
        spend: (acc.spend ?? 0) + (item.spend ?? 0),
        orders: (acc.orders ?? 0) + (item.orders ?? 0),
        sourceOrders: (acc.sourceOrders ?? 0) + (item.sourceOrders ?? 0),
        ordersValue: (acc.ordersValue ?? 0) + (item.ordersValue ?? 0),
        sourceOrdersValue: (acc.sourceOrdersValue ?? 0) + (item.sourceOrdersValue ?? 0),
      }),
      {} as { [key: string]: number },
    )
}

export const dataSelector = (state: DashboardMachineState) => {
  const { selectedCampaigns, selectedAdsets, selectedAds, data } = state.context

  switch (state.context.tab) {
    case AttributionTableTabs.CAMPAIGNS:
      return selectedCampaigns.length > 0 ? aggregateSelectedItems(data.campaignsQuery?.campaigns ?? [], selectedCampaigns) : data.campaignsQuery?.aggregates
    case AttributionTableTabs.ADSETS:
      return selectedAdsets.length > 0 ? aggregateSelectedItems(data.adsetsQuery?.adsets ?? [], selectedAdsets) : data.adsetsQuery?.aggregates
    case AttributionTableTabs.ADS:
      return selectedAds.length > 0 ? aggregateSelectedItems(data.adsQuery?.ads ?? [], selectedAds) : data.adsQuery?.aggregates
  }
}
