import React, { useContext } from 'react'
import { Select as _Select, Tooltip } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import styled from 'styled-components'
import { GlobalStateContext } from '../../../contexts/GlobalStateContext'
import { DashboardMachineState } from '../../../machines/dashboardMachine/dashboardMachine'
import { useSelector } from '@xstate/react'
import { Attribution } from '../../../__generated__/graphql'

const Select = styled(_Select)`
  .ant-select-selector {
    border-radius: 12px !important;
  }
`

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const { Option } = Select

interface AttributionOption {
  label: string
  attribution: Attribution
  tooltip?: string
}

const FACEBOOK_VIEW_TOOLTIP = 'Facebook supports View attribution, but the FoxTrax pixel metrics do not. FoxTrax metrics will show no data, but Facebook metrics will show data.'
const FIRST_CLICK_TOOLTIP = 'Facebook may not support First Click attribution, but FoxTrax pixel metrics do. Facebook metrics requiring attribution will show no data, but other metrics like spend will still appear.'

const attributionOptions: AttributionOption[] = [
  { label: '1 Day Last Click', attribution: Attribution.OneDayLastClick },
  { label: '7 Day Last Click', attribution: Attribution.SevenDayLastClick },
  {
    label: '7 Day Click or 1 Day View',
    attribution: Attribution.SevenDayClickOrOneDayView,
    tooltip: FACEBOOK_VIEW_TOOLTIP,
  },
  {
    label: '7 Day First Click',
    attribution: Attribution.SevenDayFirstClick,
    tooltip: FIRST_CLICK_TOOLTIP,
  },
  {
    label: '1 Day First Click',
    attribution: Attribution.OneDayFirstClick,
    tooltip: FIRST_CLICK_TOOLTIP,
  },
  {
    label: '7 Day View',
    attribution: Attribution.SevenDayView,
    tooltip: FACEBOOK_VIEW_TOOLTIP,
  },
  {
    label: '1 Day View',
    attribution: Attribution.OneDayView,
    tooltip: FACEBOOK_VIEW_TOOLTIP,
  },
]

const attributionSettingSelector = (state: DashboardMachineState) => state.context.attributionSetting

export const AttributionSelect: React.FC = () => {
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService

  const attributionSetting = useSelector(globalServices.dashboardService, attributionSettingSelector)
  const setAttributionSetting = (attributionSetting: { attribution: Attribution }) => send({ type: 'SET_ATTRIBUTION_SETTINGS_EVENT', attributionSetting })

  const handleChange = (value: string) => {
    const newOption = attributionOptions.find((option) => option.label === value)
    if (newOption) {
      setAttributionSetting({ attribution: newOption.attribution })
    }
  }

  const currentLabel = attributionOptions.find((option) => option.attribution === attributionSetting.attribution)?.label || attributionOptions[0].label

  return (
    <Select size="large" value={currentLabel} style={{ width: '250px' }} onChange={handleChange as any}>
      {attributionOptions.map((option) => (
        <Option key={option.label} value={option.label}>
          <OptionWrapper>
            {option.label}
            {option.tooltip && (
              <Tooltip title={option.tooltip} placement="right">
                <InfoCircleFilled style={{ fontSize: '16px', color: '#1890ff' }} />
              </Tooltip>
            )}
          </OptionWrapper>
        </Option>
      ))}
    </Select>
  )
}
