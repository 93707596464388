import { Col, Row as _Row } from 'antd'
import styled from 'styled-components'
import { CreateStoreForm } from '../../components/CreateStoreForm'
import { breakpoints } from '../../theme/breakpoints'
import { Background } from '../../components/StyledComponents'
import fox from "../../assets/snow-fox-2.jpg"

const Row = styled(_Row)`
  min-height: 100vh;
  height: fit-content;
`

const Container = styled.div`
  padding: 16px;
  @media (max-width: ${breakpoints.sm}) {
    height: 100%;
    margin: auto;
    padding: 0;
  }
`

export const CreateStore = () => {
  return (
    <Row>
      <Col xs={0} sm={0} md={12} lg={12}>
        <Background src={fox} scale='stretch'>
        </Background>
      </Col>
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        xs={24}
        sm={24}
        md={12}
        lg={12}
      >
        <Container>
          <CreateStoreForm />
        </Container>
      </Col>
    </Row>
  )
}
