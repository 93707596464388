import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Pagination as _Pagination, Row, Select as _Select, Space, Typography, Table as _Table, theme, Col } from 'antd'
import { ColumnGroupType, ColumnType, TableRowSelection } from 'antd/es/table/interface'
import styled from 'styled-components'
import { useCallback } from 'react'
import { SortInput } from '../../__generated__/graphql'
import { useMultiSort } from '../../utils/useMultiSort'
import { TableSummary } from './TableSummary'
import { RowKey } from '../../machines/dashboardMachine/dashboardMachine'

const { Text: _Text } = Typography

const Table = styled(_Table)<{ headerbackground: string; primarycolor: string; colortext: string }>`
  .ant-table-column-title{
    user-select: none;
  },
  .ant-table-thead th.ant-table-column-sort,
  .ant-table-thead .ant-table-cell {
    color: #6f767e !important;
    background: white;
    background-color: ${(props) => props.headerbackground};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #eeeeee;
    border-right: 0.5px solid #eeeeee;
  }

  .ant-table-thead th.ant-table-column-has-sorters {
    &:hover {
      color: ${(props) => props.primarycolor} !important;
    }
  }

  th.ant-table-column-sort {
    color: ${(props) => props.primarycolor} !important;
  }
  .ant-table-thead th.ant-table-column-has-sorters::before {
    background-color: #f0f0f0 !important;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover::before {
    background-color: #f0f0f0 !important;
  }

  td.ant-table-column-sort {
    color: ${(props) => props.colortext} !important;
  }

  td.ant-table-column-sort:hover {
    color: ${(props) => props.colortext} !important;
  }
`

const Select = styled(_Select)`
  height: auto;
  .ant-select-selector {
    border-radius: 100px;
    height: 44px !important;
    padding: 6px 20px !important;
  }
  .ant-select-selection-item::before {
    content: 'Show ';
  }
`
const Pagination = styled(_Pagination)`
  li {
    height: auto !important;
  }
`

export interface TableRow {
  key: string
  id: string
  name: string
  status: string
  budget: number
  spend: number
  on: boolean
  orders?: number | null
  ordersValue?: number | null
  sourceOrders?: number | null
  sourceOrdersValue?: number | null
}

interface TableProps {
  title: string
  columns: (ColumnGroupType<TableRow> | ColumnType<TableRow>)[]
  data: TableRow[]
  limit: number
  offset: number
  sort: SortInput[]
  total?: number
  loading?: boolean
  selectedRowKeys: RowKey[]
  setOffset: (offset: number) => void
  setSort: (sort: SortInput[]) => void
  setLimit: (limit: number) => void
  setSelectedRowKeys: (keys: RowKey[]) => void
}

export const AttributionTable = ({ title, columns, data, limit, offset, sort, total, loading, selectedRowKeys, setOffset, setSort, setLimit, setSelectedRowKeys }: TableProps) => {
  const {
    token: { colorBorder, colorBgContainer, colorPrimary, colorText },
  } = theme.useToken()

  const { getOrderForColumn, updateOrderWithSorter } = useMultiSort(sort, setSort)

  const readiedColumns = columns.map((column) => {
    if (column.sorter !== false) {
      return {
        ...column,
        sorter: { multiple: 1 },
        sortOrder: getOrderForColumn(column.key as string),
      }
    } else {
      return column
    }
  })

  const tableItemRender = useCallback(
    (_current: number, type: 'prev' | 'next' | 'page' | 'jump-prev' | 'jump-next', originalElement: React.ReactNode) => {
      if (type === 'prev') {
        return <LeftOutlined style={{ border: `solid 1px ${colorBorder}`, borderRadius: 100, height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
      }
      if (type === 'next') {
        return <RightOutlined style={{ border: `solid 1px ${colorBorder}`, borderRadius: 100, height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
      }
      return originalElement
    },
    [data],
  )

  const rowSelection: TableRowSelection<TableRow> = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: (selectedRowKeys: RowKey[]) => setSelectedRowKeys(selectedRowKeys),
    getCheckboxProps: (record: TableRow) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  const tableSummary = TableSummary(columns, selectedRowKeys, title)

  return (
    <>
      <Table
        colortext={colorText}
        showSorterTooltip={{ target: 'sorter-icon' }}
        loading={loading}
        headerbackground={colorBgContainer}
        primarycolor={colorPrimary}
        columns={readiedColumns as ColumnType<object>[]}
        dataSource={[...data]}
        pagination={false}
        size="middle"
        rowSelection={rowSelection as TableRowSelection<object>}
        summary={() => tableSummary}
        scroll={{ x: 1000, y: 500 }}
        onChange={(_pagination, _filters, sorter, { action }) => {
          if (action === 'sort') {
            updateOrderWithSorter(sorter)
          }
        }}
      />
      <Row gutter={[8, 8]} justify={'space-between'} align={'middle'} style={{ padding: '16px' }}>
        <Col>
          <Pagination
            style={{ alignItems: 'center', marginBottom: 4 }}
            pageSize={limit}
            current={offset}
            total={total}
            onChange={(offset: number) => {
              setOffset(offset)
            }}
            showSizeChanger={false}
            itemRender={tableItemRender}
          />
        </Col>
        <Col>
          <Row justify={'end'}>
            <Space>
              <Typography.Text>
                Showing {`${(offset - 1) * limit + 1} to ${(offset - 1) * limit + data.length}`} of {total} entries
              </Typography.Text>
              <Select
                onChange={(value) => setLimit(value as number)}
                value={limit}
                defaultValue={10}
                options={[
                  { value: 10, label: '10' },
                  { value: 25, label: '25' },
                  { value: 50, label: '50' },
                ]}
              ></Select>
            </Space>
          </Row>
        </Col>
      </Row>
    </>
  )
}
