import { Card, Divider, Space, theme } from 'antd'
import { ProfileAction } from '../../components/HeaderActions/Profile'
import { CopyableText } from '../../components/StyledComponents'
import { ResponsiveSiderLayout } from '../../components/ResponsiveSiderLayout'
import { SetupStep } from '../../components/SetupStep'
import ReactPlayer from 'react-player'
import image from '../../assets/quickstart_assets/utm_field.png'

const copyableText = 'fx_platform=facebook&fx_id={{ad.id}}'

export const SetupUTM = () => {
  const {
    token: { colorBgLayout },
  } = theme.useToken()
  return (
    <ResponsiveSiderLayout title="How to setup UTM codes" primaryPageKey="setup-utm" actions={<ProfileAction />} mobileActions={[<ProfileAction key={4} />]}>
      <Card style={{ margin: '40px', padding: '24px' }}>
        <ReactPlayer
          width="100%"
          height="auto"
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          pip={true}
          controls={true}
          url="https://vimeo.com/925612227"
          style={{ aspectRatio: '16 / 9' }}
        />
        <Divider />
        <Space style={{ width: '100%' }} size={'large'} direction="vertical">
          <Space style={{ width: '100%' }} direction="vertical">
            <SetupStep
              key={1}
              heading={'Step 1'}
              sub={'Copy the UTM parameters below. Do not change the copied text.'}
              />
            <CopyableText text={copyableText} backgroundColor={colorBgLayout} />
          </Space>
          <SetupStep
            key={1}
            heading={'Step 2'}
            sub={'Paste the copied UTMs into the URL parameters section of the selected ad.'}
            media={{ type: 'image', src: image }}/>
        </Space>
      </Card>
    </ResponsiveSiderLayout>
  )
}
