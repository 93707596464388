import { ReloadOutlined } from '@ant-design/icons'
import { Button, Row, Tooltip, Typography, theme } from 'antd'
import { useContext, useEffect } from 'react'
import { GlobalStateContext } from '../../../contexts/GlobalStateContext'
import { useSelector } from '@xstate/react'
import { DashboardMachineState } from '../../../machines/dashboardMachine/dashboardMachine'
const { Text } = Typography

const refreshSelector = (state: DashboardMachineState) => state.context.refresh
export const ReloadAction = () => {
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService
  const refresh = useSelector(globalServices.dashboardService, refreshSelector)
  const setRefresh = (refresh: boolean) => send({ type: 'SET_REFRESH_EVENT', refresh })
  const {
    token: { colorPrimaryBg, colorTextBase },
  } = theme.useToken()

  useEffect(() => {
    setRefresh(false)
  }, [])

  return (
    <Tooltip
      color={colorPrimaryBg}
      overlayInnerStyle={{ color: colorTextBase }}
      title={
        <div style={{ padding: '8px' }}>
          <Row justify="center" align="middle">
            <Text style={{ fontSize: 12, fontWeight: 600 }}>Refresh</Text>
          </Row>
          <Row justify="center" align="middle">
            <Text style={{ fontSize: 10, fontWeight: 400 }}>Click on it to refresh your page</Text>
          </Row>
        </div>
      }
    >
      <Button
        onClick={() => {
          setRefresh(true)
          setTimeout(() => {
            setRefresh(false)
          }, 2000)
        }}
        loading={refresh}
        type="text"
        icon={<ReloadOutlined />}
      ></Button>
    </Tooltip>
  )
}
