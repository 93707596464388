import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const FacebookLogoSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none">
    <path d="M20 0C8.954 0 0 8.954 0 20C0 29.982 7.314 38.256 16.876 39.758V25.78H11.796V20H16.876V15.594C16.876 10.582 19.86 7.814 24.43 7.814C26.618 7.814 28.906 8.204 28.906 8.204V13.124H26.386C23.9 13.124 23.126 14.666 23.126 16.248V20H28.672L27.786 25.78H23.126V39.758C32.686 38.258 40 29.98 40 20C40 8.954 31.046 0 20 0Z" fill="white"/>
  </svg>
)

export const FacebookLogo = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={FacebookLogoSvg} {...props} />
);