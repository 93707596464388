import { Col, Row as _Row } from 'antd'
import styled from 'styled-components'
import { Container } from '../components/StyledComponents/OnboardingContainer'
import { Background } from '../components/StyledComponents/OnboardingBackground'
import fox from '../assets/portal-fox.jpg'

const Row = styled(_Row)`
  min-height: 100vh;
  height: fit-content;
`

export const PasswordResetLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Row>
      <Col xs={0} sm={0} md={12} lg={12}>
        <Background src={fox} offset="-104px"></Background>
      </Col>
      <Col
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        xs={24}
        sm={24}
        md={12}
        lg={12}
      >
        <Container>{children}</Container>
      </Col>
    </Row>
  )
}
