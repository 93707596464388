import { useSelector } from '@xstate/react'
import { useContext } from 'react'
import { SortInput } from '../../__generated__/graphql'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { DashboardMachineState, RowKey } from '../../machines/dashboardMachine/dashboardMachine'
import { getColumns } from './columns'
import { AttributionTable } from '../AttributionTable'

const selectedAdsSelector = (state: DashboardMachineState) => state.context.selectedAds
const limitSelector = (state: DashboardMachineState) => state.context.limit
const offsetSelector = (state: DashboardMachineState) => state.context.offset.ads
const sortSelector = (state: DashboardMachineState) => state.context.sort.ads
const dataSelector = (state: DashboardMachineState) => state.context.data.adsQuery

export const AdsTable = () => {
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService

  const selectedAds = useSelector(globalServices.dashboardService, selectedAdsSelector)
  const limit = useSelector(globalServices.dashboardService, limitSelector)
  const offset = useSelector(globalServices.dashboardService, offsetSelector)
  const sort = useSelector(globalServices.dashboardService, sortSelector)
  const data = useSelector(globalServices.dashboardService, dataSelector)

  const setSelectedAds = (selectedAds: RowKey[]) => send({ type: 'SET_SELECTED_ADS_EVENT', selectedAds })
  const setAdsTableOffset = (offset: number) => send({ type: 'SET_ADS_TABLE_OFFSET_EVENT', offset })
  const setLimit = (limit: number) => send({ type: 'SET_LIMIT_EVENT', limit })
  const setAdsSort = (sort: SortInput[]) => send({ type: 'SET_ADS_TABLE_SORT_EVENT', sort })

  return (
    <AttributionTable
      title="Ads"
      sort={sort}
      columns={getColumns(false)}
      // loading={loading}
      // TODO: Get Rid of mapping here by backend returning correct values and keys
      data={(data?.ads || []).map(({ id, name, sourceAdId, status, spend, orders, ordersValue, sourceOrders, sourceOrdersValue }) => {
        return {
          key: id,
          name,
          status,
          spend: spend ?? 0,
          budget: spend ?? 0,
          on: true,
          orders: orders,
          ordersValue: ordersValue,
          sourceOrders: sourceOrders,
          sourceOrdersValue: sourceOrdersValue,
          id: sourceAdId,
        }
      })}
      limit={limit}
      total={data?.total}
      selectedRowKeys={selectedAds}
      setLimit={setLimit}
      offset={offset}
      setSort={setAdsSort}
      setOffset={setAdsTableOffset}
      setSelectedRowKeys={setSelectedAds}
    />
  )
}
