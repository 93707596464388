import { Col, Image, Spin, Typography } from 'antd'
import styled from 'styled-components'
import supportLogo from '../../assets/support_logo.svg'
import { useViewerQuery } from '../../__generated__/graphql'
import { CopyableText } from '../../components/StyledComponents/CopyableText'

const { Text } = Typography

interface DeviceInfo {
  browserName: string
  platform: string
  language: string
  vendor: string
  screenWidth: number
  screenHeight: number
  pixelRatio: number
}

const getDeviceInfo = (): DeviceInfo => {
  return {
    browserName: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    vendor: navigator.vendor,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    pixelRatio: window.devicePixelRatio,
  }
}

const deviceInfo = getDeviceInfo()

const SupportTitleText = styled(Text)`
  color: var(--neutral-06, #272b30);
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.72px;
`

const SupportSubtitleText = styled(Text)`
  color: #575757;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
`

export const SupportSettingsForm = () => {
  const supportEmail = 'support@foxtrax.io'
  const viewerResult = useViewerQuery({
    onCompleted: (data) => {
      console.log(data.viewer)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  if (viewerResult.loading) {
    return <Spin />
  }

  if (!viewerResult.data?.viewer) {
    return <Text>Sorry, something went wrong.</Text>
  }

  const subject = `Support Request (ID: ${viewerResult.data?.viewer?.id})`
  const emailBody = `Dear Support Team,

I am having some problems with my account and would like to get some help. Here are some details about my account and device so you can better assist me:

ID: ${viewerResult.data?.viewer?.id}
Email: ${viewerResult.data?.viewer?.email}
UserFullName: ${viewerResult.data?.viewer?.firstName} ${viewerResult.data?.viewer?.lastName}
Device Info:
  - Browser Name: ${deviceInfo.browserName}
  - Platform: ${deviceInfo.platform}
  - Language: ${deviceInfo.language}
  - Vendor: ${deviceInfo.vendor}
  - Screen Width: ${deviceInfo.screenWidth}
  - Screen Height: ${deviceInfo.screenHeight}
  - Pixel Ratio: ${deviceInfo.pixelRatio}

My problem is:

{Please describe your problem here}

Thanks,
${viewerResult.data?.viewer?.firstName}`

  return (
    <Col style={{ width: '100%' }}>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <br />
          <Image src={supportLogo} />
          <SupportTitleText>Contact Support</SupportTitleText>
          <br />
          <SupportSubtitleText>Need help? Copy the email title and message below into an email to us for quicker assistance.</SupportSubtitleText>
          <SupportSubtitleText style={{ fontSize: '12px', color: '#888' }}>If you have a mail client on your computer, click <a href={`mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`}>this link</a> to create an email with the details pre-filled.</SupportSubtitleText>
          <br />
          <br />
            <Col style={{ width: '100%' }}>
              <Col style={{ marginBottom: '16px' }}>
                <Text strong>Email:</Text>
                <CopyableText text={supportEmail} backgroundColor="#e0e0e0" />
              </Col>
              <Col style={{ marginBottom: '16px' }}>
                <Text strong>Subject:</Text>
                <CopyableText text={subject} backgroundColor="#e0e0e0" />
              </Col>
              <Col>
                <Text strong>Message:</Text>
                <CopyableText text={emailBody} showButtonInUpperRight={true} backgroundColor="#e0e0e0" />
              </Col>
            </Col>
       
        </Col>
    </Col>
  )
}
