import { Card, Space, theme, Typography } from 'antd'
import styled from 'styled-components'
// import { colorCardSecondary } from '../../theme/colors'
import Flicking from '@egjs/react-flicking'
import '@egjs/react-flicking/dist/flicking.css'
import { useContext, useEffect, useState, useRef } from 'react'
import revenueIcon from '../../assets/dashboard_assets/revenue_icon.svg'
import orderIcon from '../../assets/dashboard_assets/order_icon.svg'
import spentIcon from '../../assets/dashboard_assets/spent_icon.svg'
import { dataSelector } from '../../utils/dashboard-util'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import { useSelector } from '@xstate/react/lib/useSelector'

const { Text } = Typography

const CardText = styled(Text)`
  font-size: 13px;
  font-weight: 600;
  color: white;
`

const FigureText = styled(Text)`
  font-size: var(--figure-font-size, 24px); /* Default to 24px if variable is not set */
  font-weight: 700;
  color: white;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: fit-content;
`

const MetricCard = styled(Card)`
  border-radius: 25px;
`
// const MinorCardText = styled(Text)`
//   font-size: 13px;
//   font-weight: 600;
//   line-height: 16px;
//   color: black;
// `
// const MinorFigureText = styled(Text)`
//   font-size: 28px;
//   font-weight: 700;
//   line-height: 48px;
//   color: black;
// `

// const MinorMetricCard = styled(Card)`
//   border-radius: 20px;
//   min-width: 90px;
//   padding: 0;
// `

// const minorCardData = [
//   { label: 'CPC', value: 2.5 },
//   { label: 'CTR', value: 1.5 },
//   { label: 'CPM', value: 1.77 },
//   { label: 'CPP', value: 2.5 },
//   { label: 'Spend', value: 6.55 },
//   { label: 'Spend', value: 1.22 },
// ]

const minLargeMetricCardWidth = 200
const maxLargeMetricCardCount = 5

export const MetricsContainer = () => {
  const globalServices = useContext(GlobalStateContext)
  const data = useSelector(globalServices.dashboardService, dataSelector)
  const [largePanels, setLargePanels] = useState(maxLargeMetricCardCount)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const flickerLarge = document.querySelector('#flicker-large')
    const container = containerRef.current

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target.id === 'flicker-large') {
          const width = entry.contentRect.width
          if (Math.floor(width / minLargeMetricCardWidth) > maxLargeMetricCardCount) setLargePanels(maxLargeMetricCardCount)
          else setLargePanels(Math.floor(width / minLargeMetricCardWidth))
          // Update font size based on container width
          if (container) {
            const fontSize = Math.max(16, Math.min(32, width / (largePanels * 10)))
            container.style.setProperty('--figure-font-size', `${fontSize}px`)
          }
        }
      }
    })

    if (flickerLarge) {
      resizeObserver.observe(flickerLarge)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [largePanels])

  const {
    token: { colorPrimary },
  } = theme.useToken()
  // TODO: Store this in the DB and make it dynamic
  const transformedData = [
    {
      icon: spentIcon,
      label: 'Spend',
      value: data?.spend?.toFixed(2) ?? 0,
      prefix: '$',
    },
    {
      icon: orderIcon,
      label: 'Orders (FoxTrax)',
      value: data?.orders ?? 0,
    },
    {
      icon: orderIcon,
      label: 'Orders (Facebook)',
      value: data?.sourceOrders ?? 0,
    },
    {
      icon: revenueIcon,
      label: 'Revenue (FoxTrax)',
      value: data?.ordersValue?.toFixed(2) ?? 0,
      prefix: '$',
    },
    {
      icon: revenueIcon,
      label: 'Revenue (Facebook)',
      value: data?.sourceOrdersValue?.toFixed(2) ?? 0,
      prefix: '$',
    },
  ]

  return (
    <Card style={{ borderRadius: 20, width: '100%', marginBottom: 20, zIndex: '0' }}>
      <div ref={containerRef}>
        <Flicking id="flicker-large" style={{ padding: '8px 0' }} panelsPerView={largePanels} align={'prev'} defaultIndex={0} moveType="snap" bound={true}>
          {transformedData.map(({ icon, label, value, prefix }) => {
            return (
              <div style={{ width: `100%`, padding: '0 8px', minWidth: minLargeMetricCardWidth }} key={`large-${label}`}>
                <MetricCard style={{ background: colorPrimary, width: '100%', height: '100%' }}>
                  <Space size={'small'} direction="vertical">
                    <img src={icon}></img>
                    <CardText>{label}</CardText>
                    <FigureText>{prefix ? `${prefix}${value.toLocaleString()}` : value.toLocaleString()}</FigureText>
                  </Space>
                </MetricCard>
              </div>
            )
          })}
        </Flicking>
      </div>
      {/* <Flicking style={{ padding: '8px 0' }} panelsPerView={largePanels * 2} align={'prev'} defaultIndex={0} moveType="snap" bound={true}>
        {minorCardData.map(({ label, value }, index) => (
          <div style={{ width: `100%`, padding: '0 8px', minWidth: minLargeMetricCardWidth / 2 }} key={`small-${label}`}>
            <MinorMetricCard size="small" style={{ backgroundColor: colorCardSecondary }}>
              <div>
                <MinorCardText>{label}</MinorCardText>
              </div>
              <div>
                <MinorFigureText>{value.toLocaleString()}</MinorFigureText>
              </div>
            </MinorMetricCard>
          </div>
        ))}
      </Flicking> */}
    </Card>
  )
}
