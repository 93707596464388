import { useState, useEffect } from 'react'
import { JwtContext } from '../contexts/jwtContext'
import { Jwt, decodeToken } from '../services/auth'
import { LocalStorageKeys, LocalStorageTypes } from '../common/types/localStorage'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { localStorageGetItem } from '../services/storage'

export type DecodedToken = Jwt | null

interface JwtProviderProps {
  children: React.ReactNode
}

export const JwtProvider = ({ children }: JwtProviderProps) => {
  const [decodedToken, setDecodedToken] = useState<DecodedToken>(decodeToken(localStorageGetItem(LocalStorageKeys.AuthToken)))
  const [authToken] = useLocalStorage(LocalStorageKeys.AuthToken, LocalStorageTypes.String)

  useEffect(() => {
    setDecodedToken(decodeToken(localStorageGetItem(LocalStorageKeys.AuthToken)))
  }, [authToken])

  return <JwtContext.Provider value={decodedToken}>{children}</JwtContext.Provider>
}
