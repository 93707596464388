import { ReactNode, createContext } from 'react'
import { useInterpret } from '@xstate/react'
import { DashboardMachineState, dashboardMachine } from '../machines/dashboardMachine/dashboardMachine'
import { InterpreterFrom } from 'xstate'
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from '../services/storage'
import { LocalStorageKeys } from '../common/types/localStorage'

export const GlobalStateContext = createContext({ dashboardService: {} as InterpreterFrom<typeof dashboardMachine> })

type GlobalStateProviderProps = {
  children: ReactNode
}

export const storePersistedState = (state: any) => {
  localStorageSetItem(LocalStorageKeys.PersistedState, JSON.stringify(state))
}

export const getPersistedState = () => {
  let state = null
  try {
    const item = localStorageGetItem(LocalStorageKeys.PersistedState)
    if (item === null) return null

    const parsedItem = JSON.parse(item)
    if (parsedItem === null || Array.isArray(parsedItem) || typeof parsedItem !== 'object') {
      throw new Error(`Invalid Persisted State for key: ${LocalStorageKeys.PersistedState}`)
    }
    state = parsedItem
  } catch (error) {
    console.error(error)
    localStorageRemoveItem(LocalStorageKeys.PersistedState)
  }
  return state
}


export const GlobalStateProvider = (props: GlobalStateProviderProps) => {
  const state: DashboardMachineState = getPersistedState()

  const dashboardService = useInterpret(
    dashboardMachine(state?.context),
    {
      devTools: import.meta.env.DEV,
      state,
    },
    state => storePersistedState(state),
  )

  return <GlobalStateContext.Provider value={{ dashboardService }}>{props.children}</GlobalStateContext.Provider>
}
