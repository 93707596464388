import { breakpoints, antdScreenSizes } from '../../theme/breakpoints'

export const calcBreakpoint = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl', isMobile: boolean | null, siderCollapse: boolean | null) => {
  const siderExpandedWidth = 200
  const siderCollapsedWidth = 80
  let result: string

  if (isMobile === true) {
    result = breakpoints[size]
  } else if (!siderCollapse) {
    result = `${parseInt(breakpoints[size], 10) - siderExpandedWidth}px`
  } else if (siderCollapse) {
    result = `${parseInt(breakpoints[size], 10) - siderCollapsedWidth}px`
  } else {
    result = breakpoints[size]
  }

  return result
}

function updatedScreenSizes(value: number) {
  const result = { ...antdScreenSizes }
  for (const key in antdScreenSizes) {
    result[key] = antdScreenSizes[key] + value
  }
  return result
}

export const calcScreenSizes = (isMobile: boolean | null, siderCollapse: boolean | null) => {
  const siderExpandedWidth = 200
  let result

  if (isMobile) {
    result = antdScreenSizes
  } else if (!siderCollapse) {
    result = updatedScreenSizes(siderExpandedWidth)
  } else {
    result = antdScreenSizes
  }

  return result
}
