import { AppHeader } from '../../components/AppHeader'
import logo from '../../assets/logo.svg'
import React, { ReactNode } from 'react';

interface LegalProps {
  children: ReactNode;
}

const Legal: React.FC<LegalProps> = ({ children }) => {
  return (
    <div style={{ minHeight: '100vh' }}>
      <AppHeader title="Legal" logo={logo} />
      {children}
    </div>
  )
}

export default Legal;
