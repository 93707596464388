import { Space, Typography } from 'antd'
import styled from 'styled-components'
import React from 'react'
import ReactPlayer from 'react-player'

const { Text } = Typography

interface StepComponentProps {
  heading: string
  headingDescription?: string
  sub: string
  media?: { type: 'image' | 'video', src: string } // Optional media prop to pass image or video
  content?: React.ReactNode // Optional content prop to pass widgets
}

const StepHeading = styled(Text)`
  color: #1a1d1f;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: -0.4px;
`
const StepSub = styled(Text)`
  color: #686868;
  font-size: 16px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.14px;
`

export const SetupStep = ({ heading, headingDescription, media, sub, content }: StepComponentProps) => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <StepHeading style={{ fontSize: '24px' }}>{heading} <Text>{headingDescription}</Text></StepHeading>
      {media && media.type === 'image' && <img style={{ width: '100%' }} src={media.src} />}
      {media && media.type === 'video' &&  <ReactPlayer
            width="100%"
            height="auto"
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            pip={true}
            controls={true}
            url={media.src}
            style={{ aspectRatio: '16 / 9' }}
          />}
      <StepSub style={{ color: '#686868' }}>{sub}</StepSub>
      {content && <div>{content}</div>}
    </Space>
  )
}
