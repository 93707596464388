import { Form } from "antd";
import styled
 from "styled-components";
import { colorTextTertiary } from "../../theme/colors";
const _FormItem = Form.Item;

export const FormItem = styled(_FormItem)`
  .ant-form-item-label > label {
    color: ${colorTextTertiary};
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
`;