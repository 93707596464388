import { Layout } from "antd";
import { useEffect, useState } from "react";
import { MobileAppHeader } from "./MobileAppHeader";
import { AppSider } from "../AppSider";
import { AppHeader } from "../AppHeader";
import { Content } from "antd/es/layout/layout";
interface ResponsiveSiderLayoutProps {
    title: string;
    primaryPageKey: string;
    children: React.ReactNode;
    actions?: React.ReactNode
    mobileActions?: React.ReactNode
}

export const ResponsiveSiderLayout = ({ title, primaryPageKey, children, actions, mobileActions }: ResponsiveSiderLayoutProps) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Layout hasSider={!isMobile}>
            {isMobile ? (
                <MobileAppHeader title={title} primaryPageKey={primaryPageKey} actions={mobileActions ?? actions} />
            ) : (
                <AppSider primaryPageKey={primaryPageKey} />
            )}
            <Layout className="site-layout">
                {!isMobile ? <AppHeader title={title} actions={[actions]} /> : null}
                <Content>
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};
