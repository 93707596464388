import { Row as _Row } from "antd";
import { ResetPasswordForm } from "../../components/ResetPasswordForm";
import { PasswordResetLayout } from "../../layouts/PasswordResetLayout";

export const ResetPassword = () => {
  return (
    <PasswordResetLayout>
      <ResetPasswordForm />
    </PasswordResetLayout>
  );
};
