import styled from 'styled-components'
import { Select as _Select } from 'antd'

export const Select = styled(_Select)`
  height: 60px;
  .ant-select-selector {
    border-radius: 15px !important;
    align-items: center;
    display: flex;
    height: 100% !important;
  }
  .ant-select-selection-search {
    align-items: center;
    display: flex;
  }
  .ant-select-selection-item {
    text-align: left;
  }
  .ant-select-selection-placeholder {
    text-align: left;
  }
`
