import { MenuOutlined } from "@ant-design/icons";
import { Drawer, Row, Space, Typography, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import { useState } from "react";
import styled from "styled-components";
import { NavigationMenu } from "../AppSider";

const { Text } = Typography



const MobileHeaderTitle = styled(Text) <{ textcolor: string; }>`
    color: ${(props) => props.textcolor};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.48px;
    align-self: center;
  `

interface MobileAppHeaderProps {
    title: string
    primaryPageKey: string;
    actions?: React.ReactNode
}


const Spacer = ({ width }: any) => <div style={{ width }} />;

export const MobileAppHeader = ({ title, primaryPageKey, actions }: MobileAppHeaderProps) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken()

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const {
        token: { colorText },
    } = theme.useToken()

    return (
        <Header style={{ height: 'auto', padding: '16px 24px', background: colorBgContainer, position: 'sticky', top: 0, zIndex: 2, width: '100%' }}>
            <Row justify={"space-between"} align={"middle"}>
                <Space>
                    <MenuOutlined onClick={showDrawer} />
                    <Spacer width={16} />
                    <MobileHeaderTitle textcolor={colorText}>{title}</MobileHeaderTitle>
                </Space>
                {actions}
            </Row>
            <Drawer
                placement={"left"}
                onClose={onClose}
                open={open}
            >
                {NavigationMenu(primaryPageKey)}
            </Drawer>
        </Header>
    )
}