import React from 'react';
type CSSProperties = React.CSSProperties

const styles: { [key: string]: CSSProperties } = {
    body: {
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      padding: '2rem',
      color: '#000000',
      maxWidth: '800px',
      margin: 'auto',
      backgroundColor: '#ffffff'
    },
    h1: {
      fontSize: '26pt',
      marginBottom: '0.2rem',
      pageBreakAfter: 'avoid'
    },
    h2: {
      fontSize: '20pt',
      margin: '1.5rem 0 0.5rem'
    },
    h3: {
      fontSize: '16pt',
      color: '#434343',
      margin: '1rem 0 0.5rem'
    },
    p: {
      fontSize: '11pt',
      margin: '0.5rem 0',
      textAlign: 'justify'
    },
    subtitle: {
      color: '#666666',
      fontSize: '15pt',
      marginBottom: '1rem'
    },
    highlight: {
      fontWeight: 'bold'
    }
  };
  
  function FoxTraxTerms() {
    return (
      <div style={styles.body}>
             <h1 style={styles.h1}>FoxTrax Terms of Service</h1>

<h2 style={styles.subtitle}>Last Updated: 8/2/2023</h2>

<h3 style={styles.h3}>1. Acceptance of Terms</h3>
<p style={styles.p}>
  By accessing or using FoxTrax, you confirm your agreement to be bound by these Terms. If you do not agree to these Terms, you may not access or use FoxTrax.
</p>

<h3 style={styles.h3}>2. Changes to the Terms</h3>
<p style={styles.p}>
  We reserve the right to update or modify these Terms at any time. Your continued use of the FoxTrax Services following the posting of any changes to the Terms constitutes acceptance of those changes.
</p>

<h3 style={styles.h3}>3. Software Licensing and Use</h3>
<p style={styles.p}>
  Your use of FoxTrax is under a limited, non-exclusive, non-transferable, non-sublicensable license. This license prohibits activities such as but not limited to: illegal activities, attempts to reverse engineer the software, resale, or creation of derivative works.
</p>

<h3 style={styles.h3}>4. Your Account</h3>
<p style={styles.p}>
  You are responsible for the security and confidentiality of your FoxTrax account login credentials.
</p>

<h3 style={styles.h3}>5. Subscription Terms and Billing Cycle</h3>
<p style={styles.p}>
  Upon purchasing a subscription, you agree to an initial and recurring subscription fee at the then-current subscription rate, and you accept responsibility for all recurring charges until you cancel your subscription. You may cancel your subscription at any time, subject to the terms of our cancellation policy. We bill our customers every 30 days from the date the account was initially created.
</p>
<h3 style={styles.h3}>6. Updates and Upgrades</h3>
      <p style={styles.p}>
        Users are entitled to software updates and upgrades as part of their subscription. These will be delivered and installed automatically as part of the service.
      </p>

      <h3 style={styles.h3}>7. Support and Maintenance</h3>
      <p style={styles.p}>
        FoxTrax provides standard customer support to all users. Maintenance periods will be communicated ahead of time.
      </p>

      <h3 style={styles.h3}>8. Data Handling</h3>
      <p style={styles.p}>
        FoxTrax collects and uses data in accordance with our Privacy Policy, which is incorporated by reference into these Terms.
      </p>

      <h3 style={styles.h3}>9. Third-Party Services Liability</h3>
      <p style={styles.p}>
        Our software integrates with multiple third-party platforms such as Facebook, TikTok, Pinterest, Google Ads, among others. FoxTrax is not responsible for any penalties, suspensions, restrictions, or bans incurred on these third-party platforms as a result of using our software. Users should abide by the respective terms and conditions of these third-party platforms.
      </p>

      <h3 style={styles.h3}>10. Termination</h3>
      <p style={styles.p}>
        FoxTrax reserves the right to suspend or terminate your access to our services at any time for any reason, including as a result of a violation of these Terms.
      </p>

      <h3 style={styles.h3}>11. Disclaimer of Warranties</h3>
      <p style={styles.p}>
        FoxTrax is provided "as is" and without warranty of any kind. Any use of FoxTrax is at your own risk.
      </p>

      <h3 style={styles.h3}>12. Limitation of Liability</h3>
      <p style={styles.p}>
        To the extent permitted by law, FoxTrax shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
      </p>

      <h3 style={styles.h3}>13. DMCA Notice</h3>
      <p style={styles.p}>
        FoxTrax responds to all valid notices of such copyright infringement in line with the Digital Millennium Copyright Act (DMCA).
      </p>

      <h3 style={styles.h3}>14. Integration with Third-Party Services</h3>
      <p style={styles.p}>
        FoxTrax may integrate with third-party services which are governed by their own terms of service. FoxTrax is not responsible for any issues arising from these third-party services.
      </p>

      <h3 style={styles.h3}>15. Governing Law and Jurisdiction</h3>
      <p style={styles.p}>
        These Terms and any disputes relating to these Terms or the Services will be governed by the laws of the State of Wyoming, United States, without regard to its conflict of laws rules. The exclusive jurisdiction and venue of any action with respect to the subject matter of these Terms will be the state and federal courts located in Wyoming, United States, and each of the parties hereto waives any objection to jurisdiction and venue in such courts.
      </p>

      <h3 style={styles.h3}>16. Contact Us</h3>
      <p style={styles.p}>
        If you have any questions about these Terms, please contact us at support@foxtrax.io.
      </p>
    </div>
  );
}

export default FoxTraxTerms;
  