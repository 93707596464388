import styled from "styled-components";

export const BackButton = styled.img`
  height: 24;
  width: 24;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 32px;
  cursor: pointer;
`;