import styled from "styled-components";
import { Card as _Card } from "antd";
import { breakpoints } from "../../theme/breakpoints";

export const Card = styled(_Card)`
  max-width: 540px;
  margin: auto;
  padding: 0 16px;
  @media (max-width: ${breakpoints.sm}){
    height: 100%;
    display: flex; 
    justify-content: center; 
    align-items: center 
  }
`;