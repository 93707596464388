import { useContext } from 'react'
import { theme, Row, Col, Card as _Card } from 'antd'
import { BrowserWindowIcon } from '../../assets/dashboard_assets/browser-window'
import { FolderIcon } from '../../assets/dashboard_assets/ph_folder'
import { PromotionIcon } from '../../assets/dashboard_assets/promotion'
import { Tabs } from '../StyledComponents'
import { AttributionTableTabs, DashboardMachineState } from '../../machines/dashboardMachine/dashboardMachine'
import { TabLabel } from '../AttributionTab/AttributionTabLabel'
import { pluralize } from '../../utils/pluralize'
import { useSelector } from '@xstate/react'
import { GlobalStateContext } from '../../contexts/GlobalStateContext'
import styled from 'styled-components'
import { AdsTable, AdsetsTable, CampaignsTable } from '../AttributionTables'
import { SearchBar, SearchTerms } from '../SearchBar/SearchBar'

const Card = styled(_Card)`
  .ant-card-body {
    padding: 0;
  }
`

const selectedCampaignsCountSelector = (state: DashboardMachineState) => state.context.selectedCampaigns.length
const selectedAdsetsCountSelector = (state: DashboardMachineState) => state.context.selectedAdsets.length
const selectedAdsCountSelector = (state: DashboardMachineState) => state.context.selectedAds.length
const searchSelector = (state: DashboardMachineState) => state.context.search
const tabSelector = (state: DashboardMachineState) => state.context.tab

export const AttributionCard = () => {
  const globalServices = useContext(GlobalStateContext)
  const { send } = globalServices.dashboardService

  const selectedCampaignsCount = useSelector(globalServices.dashboardService, selectedCampaignsCountSelector)
  const selectedAdsetsCount = useSelector(globalServices.dashboardService, selectedAdsetsCountSelector)
  const selectedAdsCount = useSelector(globalServices.dashboardService, selectedAdsCountSelector)
  const search = useSelector(globalServices.dashboardService, searchSelector)
  const tab = useSelector(globalServices.dashboardService, tabSelector)

  const clearSelectedCampaigns = () => send({ type: 'CLEAR_SELECTED_CAMPAIGNS_EVENT' })
  const clearSelectedAdsets = () => send({ type: 'CLEAR_SELECTED_ADSETS_EVENT' })
  const clearSelectedAds = () => send({ type: 'CLEAR_SELECTED_ADS_EVENT' })
  const setTab = (tab: AttributionTableTabs) => send({ type: 'SET_TAB_EVENT', tab })

  const handleSearch = (searchQuery: SearchTerms[]) => {
    send({ 
      type: 'SET_SEARCH_EVENT', 
      search: searchQuery
    })
  }

  const {
    token: { colorPrimary, colorBgContainer, colorBgLayout },
  } = theme.useToken()

  const adsetsTabTitle = `Adsets ${selectedCampaignsCount > 0 && selectedAdsetsCount < 1 ? `for ${selectedCampaignsCount} ${pluralize('Campaign', selectedCampaignsCount)}` : ``}`

  const adsTabTitle = `Ads ${
    selectedAdsetsCount > 0 && selectedAdsCount < 1
      ? `for ${selectedAdsetsCount} ${pluralize('Adset', selectedAdsetsCount)}`
      : selectedCampaignsCount > 0 && selectedAdsCount < 1
      ? `for ${selectedCampaignsCount} ${pluralize('Campaign', selectedCampaignsCount)}`
      : ``
  }`

  const tables = [
    {
      label: <TabLabel title="Campaigns" Logo={FolderIcon} selectedRowsCount={selectedCampaignsCount} clearSelectedRows={clearSelectedCampaigns} />,
      title: 'Campaigns',
      key: AttributionTableTabs.CAMPAIGNS,
      children: <CampaignsTable />,
    },
    {
      label: <TabLabel title={adsetsTabTitle} Logo={BrowserWindowIcon} selectedRowsCount={selectedAdsetsCount} clearSelectedRows={clearSelectedAdsets} />,
      title: 'Ad Sets',
      key: AttributionTableTabs.ADSETS,
      children: <AdsetsTable />,
    },
    {
      label: <TabLabel title={adsTabTitle} Logo={PromotionIcon} selectedRowsCount={selectedAdsCount} clearSelectedRows={clearSelectedAds} />,
      title: 'Ads',
      key: AttributionTableTabs.ADS,
      children: <AdsTable />,
    },
  ]

  return (
    <Card style={{ borderRadius: 20, overflow: 'hidden', border: '2px solid #e6e6e6' }}>
      <Row style={{ margin: '16px 10px 8px 10px' }} justify={'space-around'} gutter={12}>
        <Col flex={1}>
          <SearchBar
            currentTab={tab}
            searchTerms={search}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Tabs
        backgroundcolorsecondary={colorBgLayout}
        backgroundactive={colorBgContainer}
        minwidth={selectedCampaignsCount > 9 || selectedAdsetsCount > 9 || selectedAdsCount > 9 ? '180px' : '170px'}
        colorprimary={colorPrimary}
        tabBarStyle={{ padding: '0 10px' }}
        style={{ backgroundColor: colorBgContainer }}
        type="card"
        activeKey={tab}
        onChange={(key) => {
          setTab(key as AttributionTableTabs)
        }}
        items={tables}
      />
    </Card>
  )
}
