import generatePicker from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import generateConfig from "./generateConfig";

const DatePicker = generatePicker<Dayjs>(generateConfig());

export type DatePickerType = typeof DatePicker & {
  generatePicker: typeof generatePicker;
};

(DatePicker as DatePickerType).generatePicker = generatePicker;

export default DatePicker as DatePickerType;