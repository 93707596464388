import { CloseCircleFilled as _CloseCircleFilled } from '@ant-design/icons'
import { Space, Tag, theme } from 'antd'
import styled from 'styled-components'

const PillWrapper = styled(Space)`
  padding-left: 4px;
  position: absolute;
  right: 0px;
  background: inherit;
  padding-right: 8px;
`

const Pill = styled(Tag)`
  padding: 4px 4px 4px 8px;
  border-radius: 100px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  border: none;
`

const CloseCircleFilled = styled(_CloseCircleFilled)`
  svg path {
    fill: white !important;
  }
`

interface SelectedPillProps {
  selectedRowCount: number
  clearSelectedRows: () => void
}

export const SelectedPill = ({ selectedRowCount, clearSelectedRows }: SelectedPillProps) => {
  const {
    token: { colorPrimary },
  } = theme.useToken()

  return (
    <PillWrapper>
      {selectedRowCount > 0 && (
        <Pill color={colorPrimary}>
          <span>{selectedRowCount} selected</span>
          <CloseCircleFilled
            onClick={(e) => {
              e.stopPropagation()
              clearSelectedRows()
            }}
            style={{ marginRight: 0, fontSize: 20 }}
          />
        </Pill>
      )}
    </PillWrapper>
  )
}
