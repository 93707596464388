import Icon from '@ant-design/icons'
import { Space as _Space } from 'antd'
import { SelectedPill } from './SelectedPill'
import styled from 'styled-components'


const TabLabelContainer = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: inherit;
`
const Title = styled.span`
  font-size: 18px;
  line-height: 18px !important;
`

const Space = styled(_Space)`
  & > .ant-space-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

interface TabelLabelProps {
  title: string
  Logo: () => JSX.Element
  selectedRowsCount: number
  clearSelectedRows: () => void
}

export const TabLabel = ({ title, Logo, selectedRowsCount, clearSelectedRows }: TabelLabelProps) => {
  return (
    <TabLabelContainer>
      <Space size={'small'}>
        <Icon component={() => <Logo />} />
        <Title>{title}</Title>
      </Space>
      <SelectedPill selectedRowCount={selectedRowsCount} clearSelectedRows={clearSelectedRows} />
    </TabLabelContainer>
  )
}
