/* eslint-disable no-restricted-imports */
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/fr'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(weekday)
dayjs.extend(localeData)

/**
 * ONLY USE THIS DAYJS SERVICE FOR GENERATING TIMESTAMP STRINGS
 *
 * For components, use the "useDayjs" hook instead: useDayjsHook.tsx
 *
 * Example usage in a non-component:
 * ```typescript
 * import dayjs from '../../services/dayjs'
 *
 * const MyUtility = (date: Parameters<typeof dayjs>[0]) => {
 *   return dayjs(date).format('YYYY-MM-DD')
 * }
 * ```
 */
const timezonedDayjs = (...args: any) => dayjs(...args).tz()
const timezonedUnix = (value: number) => dayjs.unix(value).tz()

Object.keys(dayjs).forEach((key) => {
  ;(timezonedDayjs as any)[key] = (dayjs as any)[key]
})

timezonedDayjs.unix = timezonedUnix

export { Dayjs }
export default timezonedDayjs
