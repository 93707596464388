import { useEffect, useState } from 'react'
import { LocalStorageKeys, LocalStorageTypes } from '../common/types/localStorage'
import { localStorageAddListener, localStorageRemoveListener, localStorageGetItem, localStorageSetItem } from '../services/storage'

const parseStorageValue = (value: string | null, type: LocalStorageTypes) => {
  if (value === null || value === '') return null
  switch (type) {
    case LocalStorageTypes.String:
      return value
    case LocalStorageTypes.Number:
      return Number(value)
    case LocalStorageTypes.Boolean:
      return value === 'true'
    case LocalStorageTypes.Json:
      return JSON.parse(value)
    default:
      return value
  }
}

const encodeStorageValue = (value: any, type: LocalStorageTypes): string => {
  switch (type) {
    case LocalStorageTypes.Json:
      return JSON.stringify(value)
    default:
      return String(value)
  }
}

type StorageTypeMap = {
  [LocalStorageTypes.String]: string
  [LocalStorageTypes.Number]: number
  [LocalStorageTypes.Boolean]: boolean
  [LocalStorageTypes.Json]: any
}

/**
 * A custom hook for managing values in local storage with type safety and cross-tab synchronization.
 *
 * @param key – The key to use in local storage
 * @param type – Determines the type of the stored value and the return type of the hook or null
 * @param initialDefault – The initial default value to use if no value is found in local storage
 *                        This is only used if local storage doesn't already have a value for the given key
 *
 * @returns A tuple containing the current value and a function to update it
 *
 * @example
 * const [codeChannel, setCodeChannel] = useLocalStorage(LocalStorageKeys.CodeChannel, LocalStorageTypes.String, VerifyChannel.Sms)
 * // codeChannel will be of type string | null
 * // setCodeChannel will be of type (newValue: string | null) => void
 */
export const useLocalStorage = <T extends LocalStorageTypes>(key: LocalStorageKeys, type: T, initialDefault?: StorageTypeMap[T] | null) => {
  type ValueType = StorageTypeMap[T]

  const [value, setValue] = useState<ValueType | null>(() => {
    const storedValue = localStorageGetItem(key)
    const parsedValue = parseStorageValue(storedValue, type)
    const valueToPersist = parsedValue !== null ? parsedValue : initialDefault !== undefined ? initialDefault : null
    localStorageSetItem(key, encodeStorageValue(valueToPersist, type))
    return valueToPersist
  })

  const updateLocalStorage = (newValue: ValueType | null) => {
    setValue(newValue)
    localStorageSetItem(key, encodeStorageValue(newValue, type))
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const storedValue = parseStorageValue(localStorageGetItem(key), type) as ValueType | null
      if (storedValue !== value) {
        setValue(storedValue)
      }
    }

    localStorageAddListener(key, handleStorageChange)

    return () => {
      localStorageRemoveListener(key, handleStorageChange)
    }
  }, [])

  return [value, updateLocalStorage] as const
}
